/* tslint:disable */

// This file has been generated by https://github.com/horizon-games/protoc-gen-twirp_ts.
// Do not edit.
import * as google from './google'


import {
  createTwirpRequest,
  Fetch,
  throwTwirpError
} from './twirp'

export enum WinningsDistributionFunction {
  PERCENTAGE_WINNERS_EXPONENTIAL_DISTRIBUTION_1 = 'PERCENTAGE_WINNERS_EXPONENTIAL_DISTRIBUTION_1',
  WINNER_TAKES_ALL = 'WINNER_TAKES_ALL',
  PERCENTAGE_WINNERS_FLAT_DISTRIBUTION = 'PERCENTAGE_WINNERS_FLAT_DISTRIBUTION',
  FTU_DISTRIBUTION_1 = 'FTU_DISTRIBUTION_1',
  PERCENTAGE_WINNERS_EXPONENTIAL_DISTRIBUTION_2 = 'PERCENTAGE_WINNERS_EXPONENTIAL_DISTRIBUTION_2'
}
export enum WalletType {
  Winnings = 'Winnings',
  Deposit = 'Deposit'
}
export enum WalletType {
  Winnings = 'Winnings',
  Deposit = 'Deposit'
}
export enum PassCategory {
  Mega = 'Mega',
  Casual = 'Casual',
  Trivia = 'Trivia',
  Cards = 'Cards'
}
export enum ProductType {
  ROOM_TICKET = 'ROOM_TICKET',
  GEM_PACK = 'GEM_PACK',
  PASS_BUNDLE = 'PASS_BUNDLE'
}
export enum OperatorType {
  OPERATOR_BEST = 'OPERATOR_BEST',
  OPERATOR_INCREMENT = 'OPERATOR_INCREMENT'
}
export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}
export enum Window {
  EARLY_MORNING = 'EARLY_MORNING',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  LATE_AFTERNOON = 'LATE_AFTERNOON',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT',
  LATE_NIGHT = 'LATE_NIGHT',
  SLEEP_TIME = 'SLEEP_TIME'
}
export enum TournamentType {
  Instant = 'Instant'
}
export enum KycDocumentType {
  NO_CARD = 'NO_CARD',
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  VOTER_ID = 'VOTER_ID',
  DRIVING_LICENCE = 'DRIVING_LICENCE'
}
export enum KycStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  PROCESSING = 'PROCESSING',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  MANUAL_SUPERVISION = 'MANUAL_SUPERVISION'
}
export enum UserStatusText {
  INVALID_IMAGE = 'INVALID_IMAGE',
  INFO_MISMATCH = 'INFO_MISMATCH',
  DIFFERENT_USER_DOCUMENT = 'DIFFERENT_USER_DOCUMENT',
  MINOR_USER = 'MINOR_USER'
}


export interface IBlock {
  id?: string
  name?: string
  number?: number
  entryFeeValue?: number
  entryFeeCurrency?: string
  t1t2DurationMin?: number
  t2t3DurationMin?: number
  t3t4DurationMin?: number
  durationMin?: number
  archived?: boolean
  minPlayersRequired?: number
  createdAt?: string
  createdBy?: string
  wdcId?: string
  wDName?: string
  
  toJSON?(): object
}

export interface IBlockJSON {
  id?: string
  name?: string
  number?: number
  entry_fee_value?: number
  entry_fee_currency?: string
  t1t2_duration_min?: number
  t2t3_duration_min?: number
  t3t4_duration_min?: number
  duration_min?: number
  archived?: boolean
  min_players_required?: number
  created_at?: string
  created_by?: string
  wdc_id?: string
  w_d_name?: string
  toJSON?(): object
}

export class Block implements IBlock {
  private _json: IBlockJSON

  constructor(m?: IBlock) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['number'] = m.number
      this._json['entry_fee_value'] = m.entryFeeValue
      this._json['entry_fee_currency'] = m.entryFeeCurrency
      this._json['t1t2_duration_min'] = m.t1t2DurationMin
      this._json['t2t3_duration_min'] = m.t2t3DurationMin
      this._json['t3t4_duration_min'] = m.t3t4DurationMin
      this._json['duration_min'] = m.durationMin
      this._json['archived'] = m.archived
      this._json['min_players_required'] = m.minPlayersRequired
      this._json['created_at'] = m.createdAt
      this._json['created_by'] = m.createdBy
      this._json['wdc_id'] = m.wdcId
      this._json['w_d_name'] = m.wDName
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // number (number)
  public get number(): number {
    return this._json.number!
  }
  public set number(value: number) {
    this._json.number = value
  }
  
  // entryFeeValue (entry_fee_value)
  public get entryFeeValue(): number {
    return this._json.entry_fee_value!
  }
  public set entryFeeValue(value: number) {
    this._json.entry_fee_value = value
  }
  
  // entryFeeCurrency (entry_fee_currency)
  public get entryFeeCurrency(): string {
    return this._json.entry_fee_currency!
  }
  public set entryFeeCurrency(value: string) {
    this._json.entry_fee_currency = value
  }
  
  // t1t2DurationMin (t1t2_duration_min)
  public get t1t2DurationMin(): number {
    return this._json.t1t2_duration_min!
  }
  public set t1t2DurationMin(value: number) {
    this._json.t1t2_duration_min = value
  }
  
  // t2t3DurationMin (t2t3_duration_min)
  public get t2t3DurationMin(): number {
    return this._json.t2t3_duration_min!
  }
  public set t2t3DurationMin(value: number) {
    this._json.t2t3_duration_min = value
  }
  
  // t3t4DurationMin (t3t4_duration_min)
  public get t3t4DurationMin(): number {
    return this._json.t3t4_duration_min!
  }
  public set t3t4DurationMin(value: number) {
    this._json.t3t4_duration_min = value
  }
  
  // durationMin (duration_min)
  public get durationMin(): number {
    return this._json.duration_min!
  }
  public set durationMin(value: number) {
    this._json.duration_min = value
  }
  
  // archived (archived)
  public get archived(): boolean {
    return this._json.archived!
  }
  public set archived(value: boolean) {
    this._json.archived = value
  }
  
  // minPlayersRequired (min_players_required)
  public get minPlayersRequired(): number {
    return this._json.min_players_required!
  }
  public set minPlayersRequired(value: number) {
    this._json.min_players_required = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // createdBy (created_by)
  public get createdBy(): string {
    return this._json.created_by!
  }
  public set createdBy(value: string) {
    this._json.created_by = value
  }
  
  // wdcId (wdc_id)
  public get wdcId(): string {
    return this._json.wdc_id!
  }
  public set wdcId(value: string) {
    this._json.wdc_id = value
  }
  
  // wDName (w_d_name)
  public get wDName(): string {
    return this._json.w_d_name!
  }
  public set wDName(value: string) {
    this._json.w_d_name = value
  }
  
  static fromJSON(m: IBlockJSON = {}): Block {
    return new Block({
      id: m['id']!,
      name: m['name']!,
      number: m['number']!,
      entryFeeValue: m['entry_fee_value']!,
      entryFeeCurrency: m['entry_fee_currency']!,
      t1t2DurationMin: m['t1t2_duration_min']!,
      t2t3DurationMin: m['t2t3_duration_min']!,
      t3t4DurationMin: m['t3t4_duration_min']!,
      durationMin: m['duration_min']!,
      archived: m['archived']!,
      minPlayersRequired: m['min_players_required']!,
      createdAt: m['created_at']!,
      createdBy: m['created_by']!,
      wdcId: m['wdc_id']!,
      wDName: m['w_d_name']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IEnableDisableUserRequest {
  playerId?: string
  
  toJSON?(): object
}

export interface IEnableDisableUserRequestJSON {
  player_id?: string
  toJSON?(): object
}

export class EnableDisableUserRequest implements IEnableDisableUserRequest {
  private _json: IEnableDisableUserRequestJSON

  constructor(m?: IEnableDisableUserRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  static fromJSON(m: IEnableDisableUserRequestJSON = {}): EnableDisableUserRequest {
    return new EnableDisableUserRequest({
      playerId: m['player_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IEnableDisableUserResponse {
  success?: boolean
  
  toJSON?(): object
}

export interface IEnableDisableUserResponseJSON {
  success?: boolean
  toJSON?(): object
}

export class EnableDisableUserResponse implements IEnableDisableUserResponse {
  private _json: IEnableDisableUserResponseJSON

  constructor(m?: IEnableDisableUserResponse) {
    this._json = {}
    if (m) {
      this._json['success'] = m.success
    }
  }
  
  // success (success)
  public get success(): boolean {
    return this._json.success!
  }
  public set success(value: boolean) {
    this._json.success = value
  }
  
  static fromJSON(m: IEnableDisableUserResponseJSON = {}): EnableDisableUserResponse {
    return new EnableDisableUserResponse({
      success: m['success']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignWinningsDistributionRequest {
  playerId?: string
  amount?: number
  walletType?: WalletType

  toJSON?(): object
}

export interface ICampaignWinningsDistributionRequestJSON {
  player_id?: string
  amount?: number
  wallet_type?: WalletType
  toJSON?(): object
}

export class CampaignWinningsDistributionRequest implements ICampaignWinningsDistributionRequest {
  private _json: ICampaignWinningsDistributionRequestJSON

  constructor(m?: ICampaignWinningsDistributionRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['amount'] = m.amount
      this._json['wallet_type'] = m.walletType
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // amount (amount)
  public get amount(): number {
    return this._json.amount!
  }
  public set amount(value: number) {
    this._json.amount = value
  }
  
  // walletType (wallet_type)
  public get walletType(): WalletType {
    return (<any>WalletType)[this._json.wallet_type!]
  }
  public set walletType(value: WalletType) {
    this._json.wallet_type = value
  }

  static fromJSON(m: ICampaignWinningsDistributionRequestJSON = {}): CampaignWinningsDistributionRequest {
    return new CampaignWinningsDistributionRequest({
      playerId: m['player_id']!,
      amount: m['amount']!,
      walletType: (<any>WalletType)[m['wallet_type']!]!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignWinningsDistributionResponse {
  orderRef?: string
  
  toJSON?(): object
}

export interface ICampaignWinningsDistributionResponseJSON {
  order_ref?: string
  toJSON?(): object
}

export class CampaignWinningsDistributionResponse implements ICampaignWinningsDistributionResponse {
  private _json: ICampaignWinningsDistributionResponseJSON

  constructor(m?: ICampaignWinningsDistributionResponse) {
    this._json = {}
    if (m) {
      this._json['order_ref'] = m.orderRef
    }
  }
  
  // orderRef (order_ref)
  public get orderRef(): string {
    return this._json.order_ref!
  }
  public set orderRef(value: string) {
    this._json.order_ref = value
  }
  
  static fromJSON(m: ICampaignWinningsDistributionResponseJSON = {}): CampaignWinningsDistributionResponse {
    return new CampaignWinningsDistributionResponse({
      orderRef: m['order_ref']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IAnyProduct {
  baseProduct?: BaseProduct
  roomTicketProperty?: RoomTicketProperties
  passProperties?: PassProperties
  
  toJSON?(): object
}

export interface IAnyProductJSON {
  base_product?: BaseProduct
  room_ticket_property?: RoomTicketProperties
  pass_properties?: PassProperties
  toJSON?(): object
}

export class AnyProduct implements IAnyProduct {
  private _json: IAnyProductJSON

  constructor(m?: IAnyProduct) {
    this._json = {}
    if (m) {
      this._json['base_product'] = m.baseProduct
      this._json['room_ticket_property'] = m.roomTicketProperty
      this._json['pass_properties'] = m.passProperties
    }
  }
  
  // baseProduct (base_product)
  public get baseProduct(): BaseProduct {
    return this._json.base_product!
  }
  public set baseProduct(value: BaseProduct) {
    this._json.base_product = value
  }
  
  // roomTicketProperty (room_ticket_property)
  public get roomTicketProperty(): RoomTicketProperties {
    return this._json.room_ticket_property!
  }
  public set roomTicketProperty(value: RoomTicketProperties) {
    this._json.room_ticket_property = value
  }
  
  // passProperties (pass_properties)
  public get passProperties(): PassProperties {
    return this._json.pass_properties!
  }
  public set passProperties(value: PassProperties) {
    this._json.pass_properties = value
  }
  
  static fromJSON(m: IAnyProductJSON = {}): AnyProduct {
    return new AnyProduct({
      baseProduct: BaseProduct.fromJSON(m['base_product']!),
      roomTicketProperty: RoomTicketProperties.fromJSON(m['room_ticket_property']!),
      passProperties: PassProperties.fromJSON(m['pass_properties']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IBaseProduct {
  price?: Price
  type?: ProductType
  
  toJSON?(): object
}

export interface IBaseProductJSON {
  price?: Price
  type?: ProductType
  toJSON?(): object
}

export class BaseProduct implements IBaseProduct {
  private _json: IBaseProductJSON

  constructor(m?: IBaseProduct) {
    this._json = {}
    if (m) {
      this._json['price'] = m.price
      this._json['type'] = m.type
    }
  }
  
  // price (price)
  public get price(): Price {
    return this._json.price!
  }
  public set price(value: Price) {
    this._json.price = value
  }
  
  // type (type)
  public get type(): ProductType {
    return (<any>ProductType)[this._json.type!]
  }
  public set type(value: ProductType) {
    this._json.type = value
  }
  
  static fromJSON(m: IBaseProductJSON = {}): BaseProduct {
    return new BaseProduct({
      price: Price.fromJSON(m['price']!),
      type: (<any>ProductType)[m['type']!]!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRoomTicketProperties {
  usage?: Usage
  
  toJSON?(): object
}

export interface IRoomTicketPropertiesJSON {
  usage?: Usage
  toJSON?(): object
}

export class RoomTicketProperties implements IRoomTicketProperties {
  private _json: IRoomTicketPropertiesJSON

  constructor(m?: IRoomTicketProperties) {
    this._json = {}
    if (m) {
      this._json['usage'] = m.usage
    }
  }
  
  // usage (usage)
  public get usage(): Usage {
    return this._json.usage!
  }
  public set usage(value: Usage) {
    this._json.usage = value
  }
  
  static fromJSON(m: IRoomTicketPropertiesJSON = {}): RoomTicketProperties {
    return new RoomTicketProperties({
      usage: Usage.fromJSON(m['usage']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPrice {
  currency?: Currency
  dynamic?: boolean
  mrp?: number
  listingPrice?: number
  
  toJSON?(): object
}

export interface IPriceJSON {
  currency?: Currency
  dynamic?: boolean
  mrp?: number
  listing_price?: number
  toJSON?(): object
}

export class Price implements IPrice {
  private _json: IPriceJSON

  constructor(m?: IPrice) {
    this._json = {}
    if (m) {
      this._json['currency'] = m.currency
      this._json['dynamic'] = m.dynamic
      this._json['mrp'] = m.mrp
      this._json['listing_price'] = m.listingPrice
    }
  }
  
  // currency (currency)
  public get currency(): Currency {
    return (<any>Currency)[this._json.currency!]
  }
  public set currency(value: Currency) {
    this._json.currency = value
  }
  
  // dynamic (dynamic)
  public get dynamic(): boolean {
    return this._json.dynamic!
  }
  public set dynamic(value: boolean) {
    this._json.dynamic = value
  }
  
  // mrp (mrp)
  public get mrp(): number {
    return this._json.mrp!
  }
  public set mrp(value: number) {
    this._json.mrp = value
  }
  
  // listingPrice (listing_price)
  public get listingPrice(): number {
    return this._json.listing_price!
  }
  public set listingPrice(value: number) {
    this._json.listing_price = value
  }
  
  static fromJSON(m: IPriceJSON = {}): Price {
    return new Price({
      currency: (<any>Currency)[m['currency']!]!,
      dynamic: m['dynamic']!,
      mrp: m['mrp']!,
      listingPrice: m['listing_price']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUsage {
  isLimited?: boolean
  isLeft?: boolean
  max?: number
  left?: number
  durationMinutes?: number
  expiryAt?: string
  
  toJSON?(): object
}

export interface IUsageJSON {
  is_limited?: boolean
  is_left?: boolean
  max?: number
  left?: number
  duration_minutes?: number
  expiry_at?: string
  toJSON?(): object
}

export class Usage implements IUsage {
  private _json: IUsageJSON

  constructor(m?: IUsage) {
    this._json = {}
    if (m) {
      this._json['is_limited'] = m.isLimited
      this._json['is_left'] = m.isLeft
      this._json['max'] = m.max
      this._json['left'] = m.left
      this._json['duration_minutes'] = m.durationMinutes
      this._json['expiry_at'] = m.expiryAt
    }
  }
  
  // isLimited (is_limited)
  public get isLimited(): boolean {
    return this._json.is_limited!
  }
  public set isLimited(value: boolean) {
    this._json.is_limited = value
  }
  
  // isLeft (is_left)
  public get isLeft(): boolean {
    return this._json.is_left!
  }
  public set isLeft(value: boolean) {
    this._json.is_left = value
  }
  
  // max (max)
  public get max(): number {
    return this._json.max!
  }
  public set max(value: number) {
    this._json.max = value
  }
  
  // left (left)
  public get left(): number {
    return this._json.left!
  }
  public set left(value: number) {
    this._json.left = value
  }
  
  // durationMinutes (duration_minutes)
  public get durationMinutes(): number {
    return this._json.duration_minutes!
  }
  public set durationMinutes(value: number) {
    this._json.duration_minutes = value
  }
  
  // expiryAt (expiry_at)
  public get expiryAt(): string {
    return this._json.expiry_at!
  }
  public set expiryAt(value: string) {
    this._json.expiry_at = value
  }
  
  static fromJSON(m: IUsageJSON = {}): Usage {
    return new Usage({
      isLimited: m['is_limited']!,
      isLeft: m['is_left']!,
      max: m['max']!,
      left: m['left']!,
      durationMinutes: m['duration_minutes']!,
      expiryAt: m['expiry_at']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPassProperties {
  title?: string
  passList?: Pass[]
  
  toJSON?(): object
}

export interface IPassPropertiesJSON {
  title?: string
  pass_list?: Pass[]
  toJSON?(): object
}

export class PassProperties implements IPassProperties {
  private _json: IPassPropertiesJSON

  constructor(m?: IPassProperties) {
    this._json = {}
    if (m) {
      this._json['title'] = m.title
      this._json['pass_list'] = m.passList
    }
  }
  
  // title (title)
  public get title(): string {
    return this._json.title!
  }
  public set title(value: string) {
    this._json.title = value
  }
  
  // passList (pass_list)
  public get passList(): Pass[] {
    return this._json.pass_list || []
  }
  public set passList(value: Pass[]) {
    this._json.pass_list = value
  }
  
  static fromJSON(m: IPassPropertiesJSON = {}): PassProperties {
    return new PassProperties({
      title: m['title']!,
      passList: (m['pass_list']! || []).map((v) => { return Pass.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPass {
  category?: PassCategory
  minEntryFeeValue?: number
  maxEntryFeeValue?: number
  entryFeeCurrency?: Currency
  percentRecovery?: number
  durationMins?: number
  
  toJSON?(): object
}

export interface IPassJSON {
  category?: PassCategory
  min_entry_fee_value?: number
  max_entry_fee_value?: number
  entry_fee_currency?: Currency
  percent_recovery?: number
  duration_mins?: number
  toJSON?(): object
}

export class Pass implements IPass {
  private _json: IPassJSON

  constructor(m?: IPass) {
    this._json = {}
    if (m) {
      this._json['category'] = m.category
      this._json['min_entry_fee_value'] = m.minEntryFeeValue
      this._json['max_entry_fee_value'] = m.maxEntryFeeValue
      this._json['entry_fee_currency'] = m.entryFeeCurrency
      this._json['percent_recovery'] = m.percentRecovery
      this._json['duration_mins'] = m.durationMins
    }
  }
  
  // category (category)
  public get category(): PassCategory {
    return (<any>PassCategory)[this._json.category!]
  }
  public set category(value: PassCategory) {
    this._json.category = value
  }
  
  // minEntryFeeValue (min_entry_fee_value)
  public get minEntryFeeValue(): number {
    return this._json.min_entry_fee_value!
  }
  public set minEntryFeeValue(value: number) {
    this._json.min_entry_fee_value = value
  }
  
  // maxEntryFeeValue (max_entry_fee_value)
  public get maxEntryFeeValue(): number {
    return this._json.max_entry_fee_value!
  }
  public set maxEntryFeeValue(value: number) {
    this._json.max_entry_fee_value = value
  }
  
  // entryFeeCurrency (entry_fee_currency)
  public get entryFeeCurrency(): Currency {
    return (<any>Currency)[this._json.entry_fee_currency!]
  }
  public set entryFeeCurrency(value: Currency) {
    this._json.entry_fee_currency = value
  }
  
  // percentRecovery (percent_recovery)
  public get percentRecovery(): number {
    return this._json.percent_recovery!
  }
  public set percentRecovery(value: number) {
    this._json.percent_recovery = value
  }
  
  // durationMins (duration_mins)
  public get durationMins(): number {
    return this._json.duration_mins!
  }
  public set durationMins(value: number) {
    this._json.duration_mins = value
  }
  
  static fromJSON(m: IPassJSON = {}): Pass {
    return new Pass({
      category: (<any>PassCategory)[m['category']!]!,
      minEntryFeeValue: m['min_entry_fee_value']!,
      maxEntryFeeValue: m['max_entry_fee_value']!,
      entryFeeCurrency: (<any>Currency)[m['entry_fee_currency']!]!,
      percentRecovery: m['percent_recovery']!,
      durationMins: m['duration_mins']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ILeaveTableRequest {
  playerId?: string
  tableId?: string
  tournamentId?: string
  houseId?: string
  unusedAmount?: number
  wonAmount?: number
  sessionRef?: string
  
  toJSON?(): object
}

export interface ILeaveTableRequestJSON {
  player_id?: string
  table_id?: string
  tournament_id?: string
  house_id?: string
  unused_amount?: number
  won_amount?: number
  session_ref?: string
  toJSON?(): object
}

export class LeaveTableRequest implements ILeaveTableRequest {
  private _json: ILeaveTableRequestJSON

  constructor(m?: ILeaveTableRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['table_id'] = m.tableId
      this._json['tournament_id'] = m.tournamentId
      this._json['house_id'] = m.houseId
      this._json['unused_amount'] = m.unusedAmount
      this._json['won_amount'] = m.wonAmount
      this._json['session_ref'] = m.sessionRef
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // tableId (table_id)
  public get tableId(): string {
    return this._json.table_id!
  }
  public set tableId(value: string) {
    this._json.table_id = value
  }
  
  // tournamentId (tournament_id)
  public get tournamentId(): string {
    return this._json.tournament_id!
  }
  public set tournamentId(value: string) {
    this._json.tournament_id = value
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // unusedAmount (unused_amount)
  public get unusedAmount(): number {
    return this._json.unused_amount!
  }
  public set unusedAmount(value: number) {
    this._json.unused_amount = value
  }
  
  // wonAmount (won_amount)
  public get wonAmount(): number {
    return this._json.won_amount!
  }
  public set wonAmount(value: number) {
    this._json.won_amount = value
  }
  
  // sessionRef (session_ref)
  public get sessionRef(): string {
    return this._json.session_ref!
  }
  public set sessionRef(value: string) {
    this._json.session_ref = value
  }
  
  static fromJSON(m: ILeaveTableRequestJSON = {}): LeaveTableRequest {
    return new LeaveTableRequest({
      playerId: m['player_id']!,
      tableId: m['table_id']!,
      tournamentId: m['tournament_id']!,
      houseId: m['house_id']!,
      unusedAmount: m['unused_amount']!,
      wonAmount: m['won_amount']!,
      sessionRef: m['session_ref']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ILeaveTableResponse {
  toJSON?(): object
}

export interface ILeaveTableResponseJSON {
  toJSON?(): object
}

export class LeaveTableResponse implements ILeaveTableResponse {
  private _json: ILeaveTableResponseJSON

  constructor(m?: ILeaveTableResponse) {
    this._json = {}
    if (m) {
    }
  }
  
  static fromJSON(m: ILeaveTableResponseJSON = {}): LeaveTableResponse {
    return new LeaveTableResponse({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPurchaseTicketRequest {
  playerId?: string
  tableId?: string
  tournamentId?: string
  houseId?: string
  ticketAmount?: number
  currency?: Currency
  
  toJSON?(): object
}

export interface IPurchaseTicketRequestJSON {
  player_id?: string
  table_id?: string
  tournament_id?: string
  house_id?: string
  ticket_amount?: number
  currency?: Currency
  toJSON?(): object
}

export class PurchaseTicketRequest implements IPurchaseTicketRequest {
  private _json: IPurchaseTicketRequestJSON

  constructor(m?: IPurchaseTicketRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['table_id'] = m.tableId
      this._json['tournament_id'] = m.tournamentId
      this._json['house_id'] = m.houseId
      this._json['ticket_amount'] = m.ticketAmount
      this._json['currency'] = m.currency
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // tableId (table_id)
  public get tableId(): string {
    return this._json.table_id!
  }
  public set tableId(value: string) {
    this._json.table_id = value
  }
  
  // tournamentId (tournament_id)
  public get tournamentId(): string {
    return this._json.tournament_id!
  }
  public set tournamentId(value: string) {
    this._json.tournament_id = value
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // ticketAmount (ticket_amount)
  public get ticketAmount(): number {
    return this._json.ticket_amount!
  }
  public set ticketAmount(value: number) {
    this._json.ticket_amount = value
  }
  
  // currency (currency)
  public get currency(): Currency {
    return (<any>Currency)[this._json.currency!]
  }
  public set currency(value: Currency) {
    this._json.currency = value
  }
  
  static fromJSON(m: IPurchaseTicketRequestJSON = {}): PurchaseTicketRequest {
    return new PurchaseTicketRequest({
      playerId: m['player_id']!,
      tableId: m['table_id']!,
      tournamentId: m['tournament_id']!,
      houseId: m['house_id']!,
      ticketAmount: m['ticket_amount']!,
      currency: (<any>Currency)[m['currency']!]!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPurchaseTicketResponse {
  sessionRef?: string
  
  toJSON?(): object
}

export interface IPurchaseTicketResponseJSON {
  session_ref?: string
  toJSON?(): object
}

export class PurchaseTicketResponse implements IPurchaseTicketResponse {
  private _json: IPurchaseTicketResponseJSON

  constructor(m?: IPurchaseTicketResponse) {
    this._json = {}
    if (m) {
      this._json['session_ref'] = m.sessionRef
    }
  }
  
  // sessionRef (session_ref)
  public get sessionRef(): string {
    return this._json.session_ref!
  }
  public set sessionRef(value: string) {
    this._json.session_ref = value
  }
  
  static fromJSON(m: IPurchaseTicketResponseJSON = {}): PurchaseTicketResponse {
    return new PurchaseTicketResponse({
      sessionRef: m['session_ref']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITopUpRequest {
  playerId?: string
  tableId?: string
  tournamentId?: string
  houseId?: string
  ticketAmount?: number
  currency?: Currency
  sessionRef?: string
  
  toJSON?(): object
}

export interface ITopUpRequestJSON {
  player_id?: string
  table_id?: string
  tournament_id?: string
  house_id?: string
  ticket_amount?: number
  currency?: Currency
  session_ref?: string
  toJSON?(): object
}

export class TopUpRequest implements ITopUpRequest {
  private _json: ITopUpRequestJSON

  constructor(m?: ITopUpRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['table_id'] = m.tableId
      this._json['tournament_id'] = m.tournamentId
      this._json['house_id'] = m.houseId
      this._json['ticket_amount'] = m.ticketAmount
      this._json['currency'] = m.currency
      this._json['session_ref'] = m.sessionRef
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // tableId (table_id)
  public get tableId(): string {
    return this._json.table_id!
  }
  public set tableId(value: string) {
    this._json.table_id = value
  }
  
  // tournamentId (tournament_id)
  public get tournamentId(): string {
    return this._json.tournament_id!
  }
  public set tournamentId(value: string) {
    this._json.tournament_id = value
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // ticketAmount (ticket_amount)
  public get ticketAmount(): number {
    return this._json.ticket_amount!
  }
  public set ticketAmount(value: number) {
    this._json.ticket_amount = value
  }
  
  // currency (currency)
  public get currency(): Currency {
    return (<any>Currency)[this._json.currency!]
  }
  public set currency(value: Currency) {
    this._json.currency = value
  }
  
  // sessionRef (session_ref)
  public get sessionRef(): string {
    return this._json.session_ref!
  }
  public set sessionRef(value: string) {
    this._json.session_ref = value
  }
  
  static fromJSON(m: ITopUpRequestJSON = {}): TopUpRequest {
    return new TopUpRequest({
      playerId: m['player_id']!,
      tableId: m['table_id']!,
      tournamentId: m['tournament_id']!,
      houseId: m['house_id']!,
      ticketAmount: m['ticket_amount']!,
      currency: (<any>Currency)[m['currency']!]!,
      sessionRef: m['session_ref']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITopUpResponse {
  toJSON?(): object
}

export interface ITopUpResponseJSON {
  toJSON?(): object
}

export class TopUpResponse implements ITopUpResponse {
  private _json: ITopUpResponseJSON

  constructor(m?: ITopUpResponse) {
    this._json = {}
    if (m) {
    }
  }
  
  static fromJSON(m: ITopUpResponseJSON = {}): TopUpResponse {
    return new TopUpResponse({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGame {
  id?: string
  name?: string
  description?: string
  imageUrlsMap?: Game_ImageUrlsMapEntry[]
  bundle?: GameBundle
  minimumSupportedVersion?: number
  state?: string
  createdAt?: string
  updatedAt?: string
  createdBy?: string
  orientation?: string
  
  toJSON?(): object
}

export interface IGameJSON {
  id?: string
  name?: string
  description?: string
  image_urls_map?: Game_ImageUrlsMapEntry[]
  bundle?: GameBundle
  minimum_supported_version?: number
  state?: string
  created_at?: string
  updated_at?: string
  created_by?: string
  orientation?: string
  toJSON?(): object
}

export class Game implements IGame {
  private _json: IGameJSON

  constructor(m?: IGame) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['description'] = m.description
      this._json['image_urls_map'] = m.imageUrlsMap
      this._json['bundle'] = m.bundle
      this._json['minimum_supported_version'] = m.minimumSupportedVersion
      this._json['state'] = m.state
      this._json['created_at'] = m.createdAt
      this._json['updated_at'] = m.updatedAt
      this._json['created_by'] = m.createdBy
      this._json['orientation'] = m.orientation
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // description (description)
  public get description(): string {
    return this._json.description!
  }
  public set description(value: string) {
    this._json.description = value
  }
  
  // imageUrlsMap (image_urls_map)
  public get imageUrlsMap(): Game_ImageUrlsMapEntry[] {
    return this._json.image_urls_map || []
  }
  public set imageUrlsMap(value: Game_ImageUrlsMapEntry[]) {
    this._json.image_urls_map = value
  }
  
  // bundle (bundle)
  public get bundle(): GameBundle {
    return this._json.bundle!
  }
  public set bundle(value: GameBundle) {
    this._json.bundle = value
  }
  
  // minimumSupportedVersion (minimum_supported_version)
  public get minimumSupportedVersion(): number {
    return this._json.minimum_supported_version!
  }
  public set minimumSupportedVersion(value: number) {
    this._json.minimum_supported_version = value
  }
  
  // state (state)
  public get state(): string {
    return this._json.state!
  }
  public set state(value: string) {
    this._json.state = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // updatedAt (updated_at)
  public get updatedAt(): string {
    return this._json.updated_at!
  }
  public set updatedAt(value: string) {
    this._json.updated_at = value
  }
  
  // createdBy (created_by)
  public get createdBy(): string {
    return this._json.created_by!
  }
  public set createdBy(value: string) {
    this._json.created_by = value
  }
  
  // orientation (orientation)
  public get orientation(): string {
    return this._json.orientation!
  }
  public set orientation(value: string) {
    this._json.orientation = value
  }
  
  static fromJSON(m: IGameJSON = {}): Game {
    return new Game({
      id: m['id']!,
      name: m['name']!,
      description: m['description']!,
      imageUrlsMap: (m['image_urls_map']! || []).map((v) => { return Game_ImageUrlsMapEntry.fromJSON(v) }),
      bundle: GameBundle.fromJSON(m['bundle']!),
      minimumSupportedVersion: m['minimum_supported_version']!,
      state: m['state']!,
      createdAt: m['created_at']!,
      updatedAt: m['updated_at']!,
      createdBy: m['created_by']!,
      orientation: m['orientation']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGameBundle {
  id?: string
  version?: number
  checkSum?: string
  fileName?: string
  url?: string
  className?: string
  createdAt?: string
  updatedAt?: string
  
  toJSON?(): object
}

export interface IGameBundleJSON {
  id?: string
  version?: number
  check_sum?: string
  file_name?: string
  url?: string
  class_name?: string
  created_at?: string
  updated_at?: string
  toJSON?(): object
}

export class GameBundle implements IGameBundle {
  private _json: IGameBundleJSON

  constructor(m?: IGameBundle) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['version'] = m.version
      this._json['check_sum'] = m.checkSum
      this._json['file_name'] = m.fileName
      this._json['url'] = m.url
      this._json['class_name'] = m.className
      this._json['created_at'] = m.createdAt
      this._json['updated_at'] = m.updatedAt
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // version (version)
  public get version(): number {
    return this._json.version!
  }
  public set version(value: number) {
    this._json.version = value
  }
  
  // checkSum (check_sum)
  public get checkSum(): string {
    return this._json.check_sum!
  }
  public set checkSum(value: string) {
    this._json.check_sum = value
  }
  
  // fileName (file_name)
  public get fileName(): string {
    return this._json.file_name!
  }
  public set fileName(value: string) {
    this._json.file_name = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // className (class_name)
  public get className(): string {
    return this._json.class_name!
  }
  public set className(value: string) {
    this._json.class_name = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // updatedAt (updated_at)
  public get updatedAt(): string {
    return this._json.updated_at!
  }
  public set updatedAt(value: string) {
    this._json.updated_at = value
  }
  
  static fromJSON(m: IGameBundleJSON = {}): GameBundle {
    return new GameBundle({
      id: m['id']!,
      version: m['version']!,
      checkSum: m['check_sum']!,
      fileName: m['file_name']!,
      url: m['url']!,
      className: m['class_name']!,
      createdAt: m['created_at']!,
      updatedAt: m['updated_at']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IVerifyPlayerPoolAndCreateRoomRequest {
  houseId?: string
  tourId?: string
  playerIds?: string[]
  timestamp?: string
  
  toJSON?(): object
}

export interface IVerifyPlayerPoolAndCreateRoomRequestJSON {
  house_id?: string
  tour_id?: string
  player_ids?: string[]
  timestamp?: string
  toJSON?(): object
}

export class VerifyPlayerPoolAndCreateRoomRequest implements IVerifyPlayerPoolAndCreateRoomRequest {
  private _json: IVerifyPlayerPoolAndCreateRoomRequestJSON

  constructor(m?: IVerifyPlayerPoolAndCreateRoomRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['player_ids'] = m.playerIds
      this._json['timestamp'] = m.timestamp
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // playerIds (player_ids)
  public get playerIds(): string[] {
    return this._json.player_ids || []
  }
  public set playerIds(value: string[]) {
    this._json.player_ids = value
  }
  
  // timestamp (timestamp)
  public get timestamp(): string {
    return this._json.timestamp!
  }
  public set timestamp(value: string) {
    this._json.timestamp = value
  }
  
  static fromJSON(m: IVerifyPlayerPoolAndCreateRoomRequestJSON = {}): VerifyPlayerPoolAndCreateRoomRequest {
    return new VerifyPlayerPoolAndCreateRoomRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      playerIds: (m['player_ids']! || []).map((v) => { return String(v)}),
      timestamp: m['timestamp']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedPlayersData {
  playerId?: string
  errReason?: string
  
  toJSON?(): object
}

export interface IRejectedPlayersDataJSON {
  player_id?: string
  err_reason?: string
  toJSON?(): object
}

export class RejectedPlayersData implements IRejectedPlayersData {
  private _json: IRejectedPlayersDataJSON

  constructor(m?: IRejectedPlayersData) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['err_reason'] = m.errReason
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // errReason (err_reason)
  public get errReason(): string {
    return this._json.err_reason!
  }
  public set errReason(value: string) {
    this._json.err_reason = value
  }
  
  static fromJSON(m: IRejectedPlayersDataJSON = {}): RejectedPlayersData {
    return new RejectedPlayersData({
      playerId: m['player_id']!,
      errReason: m['err_reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPlayerValidationInfo {
  isValid?: boolean
  playerId?: string
  sessionId?: string
  reason?: string
  
  toJSON?(): object
}

export interface IPlayerValidationInfoJSON {
  is_valid?: boolean
  player_id?: string
  session_id?: string
  reason?: string
  toJSON?(): object
}

export class PlayerValidationInfo implements IPlayerValidationInfo {
  private _json: IPlayerValidationInfoJSON

  constructor(m?: IPlayerValidationInfo) {
    this._json = {}
    if (m) {
      this._json['is_valid'] = m.isValid
      this._json['player_id'] = m.playerId
      this._json['session_id'] = m.sessionId
      this._json['reason'] = m.reason
    }
  }
  
  // isValid (is_valid)
  public get isValid(): boolean {
    return this._json.is_valid!
  }
  public set isValid(value: boolean) {
    this._json.is_valid = value
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // sessionId (session_id)
  public get sessionId(): string {
    return this._json.session_id!
  }
  public set sessionId(value: string) {
    this._json.session_id = value
  }
  
  // reason (reason)
  public get reason(): string {
    return this._json.reason!
  }
  public set reason(value: string) {
    this._json.reason = value
  }
  
  static fromJSON(m: IPlayerValidationInfoJSON = {}): PlayerValidationInfo {
    return new PlayerValidationInfo({
      isValid: m['is_valid']!,
      playerId: m['player_id']!,
      sessionId: m['session_id']!,
      reason: m['reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IContestConfig {
  nakamaGameLabel?: string
  tickRate?: number
  gamePlayTimeSecs?: number
  playerJoinWaitTimeSecs?: number
  gameStartCounterTimeSecs?: number
  playerDisconnectMaxTime?: number
  matchStartMinPlayerCount?: number
  maxAwayTimeSecs?: number
  nearMissMinScore?: number
  
  toJSON?(): object
}

export interface IContestConfigJSON {
  nakama_game_label?: string
  tick_rate?: number
  game_play_time_secs?: number
  player_join_wait_time_secs?: number
  game_start_counter_time_secs?: number
  player_disconnect_max_time?: number
  match_start_min_player_count?: number
  max_away_time_secs?: number
  near_miss_min_score?: number
  toJSON?(): object
}

export class ContestConfig implements IContestConfig {
  private _json: IContestConfigJSON

  constructor(m?: IContestConfig) {
    this._json = {}
    if (m) {
      this._json['nakama_game_label'] = m.nakamaGameLabel
      this._json['tick_rate'] = m.tickRate
      this._json['game_play_time_secs'] = m.gamePlayTimeSecs
      this._json['player_join_wait_time_secs'] = m.playerJoinWaitTimeSecs
      this._json['game_start_counter_time_secs'] = m.gameStartCounterTimeSecs
      this._json['player_disconnect_max_time'] = m.playerDisconnectMaxTime
      this._json['match_start_min_player_count'] = m.matchStartMinPlayerCount
      this._json['max_away_time_secs'] = m.maxAwayTimeSecs
      this._json['near_miss_min_score'] = m.nearMissMinScore
    }
  }
  
  // nakamaGameLabel (nakama_game_label)
  public get nakamaGameLabel(): string {
    return this._json.nakama_game_label!
  }
  public set nakamaGameLabel(value: string) {
    this._json.nakama_game_label = value
  }
  
  // tickRate (tick_rate)
  public get tickRate(): number {
    return this._json.tick_rate!
  }
  public set tickRate(value: number) {
    this._json.tick_rate = value
  }
  
  // gamePlayTimeSecs (game_play_time_secs)
  public get gamePlayTimeSecs(): number {
    return this._json.game_play_time_secs!
  }
  public set gamePlayTimeSecs(value: number) {
    this._json.game_play_time_secs = value
  }
  
  // playerJoinWaitTimeSecs (player_join_wait_time_secs)
  public get playerJoinWaitTimeSecs(): number {
    return this._json.player_join_wait_time_secs!
  }
  public set playerJoinWaitTimeSecs(value: number) {
    this._json.player_join_wait_time_secs = value
  }
  
  // gameStartCounterTimeSecs (game_start_counter_time_secs)
  public get gameStartCounterTimeSecs(): number {
    return this._json.game_start_counter_time_secs!
  }
  public set gameStartCounterTimeSecs(value: number) {
    this._json.game_start_counter_time_secs = value
  }
  
  // playerDisconnectMaxTime (player_disconnect_max_time)
  public get playerDisconnectMaxTime(): number {
    return this._json.player_disconnect_max_time!
  }
  public set playerDisconnectMaxTime(value: number) {
    this._json.player_disconnect_max_time = value
  }
  
  // matchStartMinPlayerCount (match_start_min_player_count)
  public get matchStartMinPlayerCount(): number {
    return this._json.match_start_min_player_count!
  }
  public set matchStartMinPlayerCount(value: number) {
    this._json.match_start_min_player_count = value
  }
  
  // maxAwayTimeSecs (max_away_time_secs)
  public get maxAwayTimeSecs(): number {
    return this._json.max_away_time_secs!
  }
  public set maxAwayTimeSecs(value: number) {
    this._json.max_away_time_secs = value
  }
  
  // nearMissMinScore (near_miss_min_score)
  public get nearMissMinScore(): number {
    return this._json.near_miss_min_score!
  }
  public set nearMissMinScore(value: number) {
    this._json.near_miss_min_score = value
  }
  
  static fromJSON(m: IContestConfigJSON = {}): ContestConfig {
    return new ContestConfig({
      nakamaGameLabel: m['nakama_game_label']!,
      tickRate: m['tick_rate']!,
      gamePlayTimeSecs: m['game_play_time_secs']!,
      playerJoinWaitTimeSecs: m['player_join_wait_time_secs']!,
      gameStartCounterTimeSecs: m['game_start_counter_time_secs']!,
      playerDisconnectMaxTime: m['player_disconnect_max_time']!,
      matchStartMinPlayerCount: m['match_start_min_player_count']!,
      maxAwayTimeSecs: m['max_away_time_secs']!,
      nearMissMinScore: m['near_miss_min_score']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRoundInfo {
  roundEnabled?: boolean
  roundNumber?: number
  totalRounds?: number
  
  toJSON?(): object
}

export interface IRoundInfoJSON {
  round_enabled?: boolean
  round_number?: number
  total_rounds?: number
  toJSON?(): object
}

export class RoundInfo implements IRoundInfo {
  private _json: IRoundInfoJSON

  constructor(m?: IRoundInfo) {
    this._json = {}
    if (m) {
      this._json['round_enabled'] = m.roundEnabled
      this._json['round_number'] = m.roundNumber
      this._json['total_rounds'] = m.totalRounds
    }
  }
  
  // roundEnabled (round_enabled)
  public get roundEnabled(): boolean {
    return this._json.round_enabled!
  }
  public set roundEnabled(value: boolean) {
    this._json.round_enabled = value
  }
  
  // roundNumber (round_number)
  public get roundNumber(): number {
    return this._json.round_number!
  }
  public set roundNumber(value: number) {
    this._json.round_number = value
  }
  
  // totalRounds (total_rounds)
  public get totalRounds(): number {
    return this._json.total_rounds!
  }
  public set totalRounds(value: number) {
    this._json.total_rounds = value
  }
  
  static fromJSON(m: IRoundInfoJSON = {}): RoundInfo {
    return new RoundInfo({
      roundEnabled: m['round_enabled']!,
      roundNumber: m['round_number']!,
      totalRounds: m['total_rounds']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IVerifyPlayerPoolAndCreateRoomResponse {
  roomId?: string
  shouldStartGame?: boolean
  playersValidationInfo?: PlayerValidationInfo[]
  contestConfig?: ContestConfig
  gameConfig?: string
  isRematchEnabled?: boolean
  roundInfo?: RoundInfo
  
  toJSON?(): object
}

export interface IVerifyPlayerPoolAndCreateRoomResponseJSON {
  room_id?: string
  should_start_game?: boolean
  players_validation_info?: PlayerValidationInfo[]
  contest_config?: ContestConfig
  game_config?: string
  is_rematch_enabled?: boolean
  round_info?: RoundInfo
  toJSON?(): object
}

export class VerifyPlayerPoolAndCreateRoomResponse implements IVerifyPlayerPoolAndCreateRoomResponse {
  private _json: IVerifyPlayerPoolAndCreateRoomResponseJSON

  constructor(m?: IVerifyPlayerPoolAndCreateRoomResponse) {
    this._json = {}
    if (m) {
      this._json['room_id'] = m.roomId
      this._json['should_start_game'] = m.shouldStartGame
      this._json['players_validation_info'] = m.playersValidationInfo
      this._json['contest_config'] = m.contestConfig
      this._json['game_config'] = m.gameConfig
      this._json['is_rematch_enabled'] = m.isRematchEnabled
      this._json['round_info'] = m.roundInfo
    }
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // shouldStartGame (should_start_game)
  public get shouldStartGame(): boolean {
    return this._json.should_start_game!
  }
  public set shouldStartGame(value: boolean) {
    this._json.should_start_game = value
  }
  
  // playersValidationInfo (players_validation_info)
  public get playersValidationInfo(): PlayerValidationInfo[] {
    return this._json.players_validation_info || []
  }
  public set playersValidationInfo(value: PlayerValidationInfo[]) {
    this._json.players_validation_info = value
  }
  
  // contestConfig (contest_config)
  public get contestConfig(): ContestConfig {
    return this._json.contest_config!
  }
  public set contestConfig(value: ContestConfig) {
    this._json.contest_config = value
  }
  
  // gameConfig (game_config)
  public get gameConfig(): string {
    return this._json.game_config!
  }
  public set gameConfig(value: string) {
    this._json.game_config = value
  }
  
  // isRematchEnabled (is_rematch_enabled)
  public get isRematchEnabled(): boolean {
    return this._json.is_rematch_enabled!
  }
  public set isRematchEnabled(value: boolean) {
    this._json.is_rematch_enabled = value
  }
  
  // roundInfo (round_info)
  public get roundInfo(): RoundInfo {
    return this._json.round_info!
  }
  public set roundInfo(value: RoundInfo) {
    this._json.round_info = value
  }
  
  static fromJSON(m: IVerifyPlayerPoolAndCreateRoomResponseJSON = {}): VerifyPlayerPoolAndCreateRoomResponse {
    return new VerifyPlayerPoolAndCreateRoomResponse({
      roomId: m['room_id']!,
      shouldStartGame: m['should_start_game']!,
      playersValidationInfo: (m['players_validation_info']! || []).map((v) => { return PlayerValidationInfo.fromJSON(v) }),
      contestConfig: ContestConfig.fromJSON(m['contest_config']!),
      gameConfig: m['game_config']!,
      isRematchEnabled: m['is_rematch_enabled']!,
      roundInfo: RoundInfo.fromJSON(m['round_info']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateRoomWithMatchIdRequest {
  houseId?: string
  roomId?: string
  matchId?: string
  
  toJSON?(): object
}

export interface IUpdateRoomWithMatchIdRequestJSON {
  house_id?: string
  room_id?: string
  match_id?: string
  toJSON?(): object
}

export class UpdateRoomWithMatchIdRequest implements IUpdateRoomWithMatchIdRequest {
  private _json: IUpdateRoomWithMatchIdRequestJSON

  constructor(m?: IUpdateRoomWithMatchIdRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['room_id'] = m.roomId
      this._json['match_id'] = m.matchId
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // matchId (match_id)
  public get matchId(): string {
    return this._json.match_id!
  }
  public set matchId(value: string) {
    this._json.match_id = value
  }
  
  static fromJSON(m: IUpdateRoomWithMatchIdRequestJSON = {}): UpdateRoomWithMatchIdRequest {
    return new UpdateRoomWithMatchIdRequest({
      houseId: m['house_id']!,
      roomId: m['room_id']!,
      matchId: m['match_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateRoomWithMatchIdResponse {
  success?: boolean
  
  toJSON?(): object
}

export interface IUpdateRoomWithMatchIdResponseJSON {
  success?: boolean
  toJSON?(): object
}

export class UpdateRoomWithMatchIdResponse implements IUpdateRoomWithMatchIdResponse {
  private _json: IUpdateRoomWithMatchIdResponseJSON

  constructor(m?: IUpdateRoomWithMatchIdResponse) {
    this._json = {}
    if (m) {
      this._json['success'] = m.success
    }
  }
  
  // success (success)
  public get success(): boolean {
    return this._json.success!
  }
  public set success(value: boolean) {
    this._json.success = value
  }
  
  static fromJSON(m: IUpdateRoomWithMatchIdResponseJSON = {}): UpdateRoomWithMatchIdResponse {
    return new UpdateRoomWithMatchIdResponse({
      success: m['success']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserScore {
  userId?: string
  score?: number
  sessionId?: string
  totalPlayTime?: string
  deathReason?: string
  roundNumber?: number
  
  toJSON?(): object
}

export interface IUserScoreJSON {
  user_id?: string
  score?: number
  session_id?: string
  total_play_time?: string
  death_reason?: string
  round_number?: number
  toJSON?(): object
}

export class UserScore implements IUserScore {
  private _json: IUserScoreJSON

  constructor(m?: IUserScore) {
    this._json = {}
    if (m) {
      this._json['user_id'] = m.userId
      this._json['score'] = m.score
      this._json['session_id'] = m.sessionId
      this._json['total_play_time'] = m.totalPlayTime
      this._json['death_reason'] = m.deathReason
      this._json['round_number'] = m.roundNumber
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // score (score)
  public get score(): number {
    return this._json.score!
  }
  public set score(value: number) {
    this._json.score = value
  }
  
  // sessionId (session_id)
  public get sessionId(): string {
    return this._json.session_id!
  }
  public set sessionId(value: string) {
    this._json.session_id = value
  }
  
  // totalPlayTime (total_play_time)
  public get totalPlayTime(): string {
    return this._json.total_play_time!
  }
  public set totalPlayTime(value: string) {
    this._json.total_play_time = value
  }
  
  // deathReason (death_reason)
  public get deathReason(): string {
    return this._json.death_reason!
  }
  public set deathReason(value: string) {
    this._json.death_reason = value
  }
  
  // roundNumber (round_number)
  public get roundNumber(): number {
    return this._json.round_number!
  }
  public set roundNumber(value: number) {
    this._json.round_number = value
  }
  
  static fromJSON(m: IUserScoreJSON = {}): UserScore {
    return new UserScore({
      userId: m['user_id']!,
      score: m['score']!,
      sessionId: m['session_id']!,
      totalPlayTime: m['total_play_time']!,
      deathReason: m['death_reason']!,
      roundNumber: m['round_number']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitGameServerMatchScoreRequest {
  houseId?: string
  tourId?: string
  roomId?: string
  matchId?: string
  userScore?: UserScore[]
  gameEnded?: boolean
  
  toJSON?(): object
}

export interface ISubmitGameServerMatchScoreRequestJSON {
  house_id?: string
  tour_id?: string
  room_id?: string
  match_id?: string
  user_score?: UserScore[]
  game_ended?: boolean
  toJSON?(): object
}

export class SubmitGameServerMatchScoreRequest implements ISubmitGameServerMatchScoreRequest {
  private _json: ISubmitGameServerMatchScoreRequestJSON

  constructor(m?: ISubmitGameServerMatchScoreRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['room_id'] = m.roomId
      this._json['match_id'] = m.matchId
      this._json['user_score'] = m.userScore
      this._json['game_ended'] = m.gameEnded
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // matchId (match_id)
  public get matchId(): string {
    return this._json.match_id!
  }
  public set matchId(value: string) {
    this._json.match_id = value
  }
  
  // userScore (user_score)
  public get userScore(): UserScore[] {
    return this._json.user_score || []
  }
  public set userScore(value: UserScore[]) {
    this._json.user_score = value
  }
  
  // gameEnded (game_ended)
  public get gameEnded(): boolean {
    return this._json.game_ended!
  }
  public set gameEnded(value: boolean) {
    this._json.game_ended = value
  }
  
  static fromJSON(m: ISubmitGameServerMatchScoreRequestJSON = {}): SubmitGameServerMatchScoreRequest {
    return new SubmitGameServerMatchScoreRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      roomId: m['room_id']!,
      matchId: m['match_id']!,
      userScore: (m['user_score']! || []).map((v) => { return UserScore.fromJSON(v) }),
      gameEnded: m['game_ended']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitGameServerMatchScoreResponse {
  toJSON?(): object
}

export interface ISubmitGameServerMatchScoreResponseJSON {
  toJSON?(): object
}

export class SubmitGameServerMatchScoreResponse implements ISubmitGameServerMatchScoreResponse {
  private _json: ISubmitGameServerMatchScoreResponseJSON

  constructor(m?: ISubmitGameServerMatchScoreResponse) {
    this._json = {}
    if (m) {
    }
  }
  
  static fromJSON(m: ISubmitGameServerMatchScoreResponseJSON = {}): SubmitGameServerMatchScoreResponse {
    return new SubmitGameServerMatchScoreResponse({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICancelTournamentRoomRequest {
  houseId?: string
  tourId?: string
  roomId?: string
  isRematchRoom?: boolean
  
  toJSON?(): object
}

export interface ICancelTournamentRoomRequestJSON {
  house_id?: string
  tour_id?: string
  room_id?: string
  is_rematch_room?: boolean
  toJSON?(): object
}

export class CancelTournamentRoomRequest implements ICancelTournamentRoomRequest {
  private _json: ICancelTournamentRoomRequestJSON

  constructor(m?: ICancelTournamentRoomRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['room_id'] = m.roomId
      this._json['is_rematch_room'] = m.isRematchRoom
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // isRematchRoom (is_rematch_room)
  public get isRematchRoom(): boolean {
    return this._json.is_rematch_room!
  }
  public set isRematchRoom(value: boolean) {
    this._json.is_rematch_room = value
  }
  
  static fromJSON(m: ICancelTournamentRoomRequestJSON = {}): CancelTournamentRoomRequest {
    return new CancelTournamentRoomRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      roomId: m['room_id']!,
      isRematchRoom: m['is_rematch_room']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICancelTournamentRoomResponse {
  success?: boolean
  reason?: string
  
  toJSON?(): object
}

export interface ICancelTournamentRoomResponseJSON {
  success?: boolean
  reason?: string
  toJSON?(): object
}

export class CancelTournamentRoomResponse implements ICancelTournamentRoomResponse {
  private _json: ICancelTournamentRoomResponseJSON

  constructor(m?: ICancelTournamentRoomResponse) {
    this._json = {}
    if (m) {
      this._json['success'] = m.success
      this._json['reason'] = m.reason
    }
  }
  
  // success (success)
  public get success(): boolean {
    return this._json.success!
  }
  public set success(value: boolean) {
    this._json.success = value
  }
  
  // reason (reason)
  public get reason(): string {
    return this._json.reason!
  }
  public set reason(value: string) {
    this._json.reason = value
  }
  
  static fromJSON(m: ICancelTournamentRoomResponseJSON = {}): CancelTournamentRoomResponse {
    return new CancelTournamentRoomResponse({
      success: m['success']!,
      reason: m['reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICheckRematchEligibilityRequest {
  houseId?: string
  tourId?: string
  lastRoomId?: string
  playerIds?: string[]
  
  toJSON?(): object
}

export interface ICheckRematchEligibilityRequestJSON {
  house_id?: string
  tour_id?: string
  last_room_id?: string
  player_ids?: string[]
  toJSON?(): object
}

export class CheckRematchEligibilityRequest implements ICheckRematchEligibilityRequest {
  private _json: ICheckRematchEligibilityRequestJSON

  constructor(m?: ICheckRematchEligibilityRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['last_room_id'] = m.lastRoomId
      this._json['player_ids'] = m.playerIds
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // lastRoomId (last_room_id)
  public get lastRoomId(): string {
    return this._json.last_room_id!
  }
  public set lastRoomId(value: string) {
    this._json.last_room_id = value
  }
  
  // playerIds (player_ids)
  public get playerIds(): string[] {
    return this._json.player_ids || []
  }
  public set playerIds(value: string[]) {
    this._json.player_ids = value
  }
  
  static fromJSON(m: ICheckRematchEligibilityRequestJSON = {}): CheckRematchEligibilityRequest {
    return new CheckRematchEligibilityRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      lastRoomId: m['last_room_id']!,
      playerIds: (m['player_ids']! || []).map((v) => { return String(v)})
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRematchConfig {
  minPlayersToStart?: number
  optOutCounterTimeSecs?: number
  entryFee?: number
  
  toJSON?(): object
}

export interface IRematchConfigJSON {
  min_players_to_start?: number
  opt_out_counter_time_secs?: number
  entry_fee?: number
  toJSON?(): object
}

export class RematchConfig implements IRematchConfig {
  private _json: IRematchConfigJSON

  constructor(m?: IRematchConfig) {
    this._json = {}
    if (m) {
      this._json['min_players_to_start'] = m.minPlayersToStart
      this._json['opt_out_counter_time_secs'] = m.optOutCounterTimeSecs
      this._json['entry_fee'] = m.entryFee
    }
  }
  
  // minPlayersToStart (min_players_to_start)
  public get minPlayersToStart(): number {
    return this._json.min_players_to_start!
  }
  public set minPlayersToStart(value: number) {
    this._json.min_players_to_start = value
  }
  
  // optOutCounterTimeSecs (opt_out_counter_time_secs)
  public get optOutCounterTimeSecs(): number {
    return this._json.opt_out_counter_time_secs!
  }
  public set optOutCounterTimeSecs(value: number) {
    this._json.opt_out_counter_time_secs = value
  }
  
  // entryFee (entry_fee)
  public get entryFee(): number {
    return this._json.entry_fee!
  }
  public set entryFee(value: number) {
    this._json.entry_fee = value
  }
  
  static fromJSON(m: IRematchConfigJSON = {}): RematchConfig {
    return new RematchConfig({
      minPlayersToStart: m['min_players_to_start']!,
      optOutCounterTimeSecs: m['opt_out_counter_time_secs']!,
      entryFee: m['entry_fee']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICheckRematchEligibilityResponse {
  canRematch?: boolean
  eligiblePlayers?: string[]
  notEligiblePlayersReason?: CheckRematchEligibilityResponse_NotEligiblePlayersReasonEntry[]
  rematchConfig?: RematchConfig
  
  toJSON?(): object
}

export interface ICheckRematchEligibilityResponseJSON {
  can_rematch?: boolean
  eligible_players?: string[]
  notEligiblePlayersReason?: CheckRematchEligibilityResponse_NotEligiblePlayersReasonEntry[]
  rematch_config?: RematchConfig
  toJSON?(): object
}

export class CheckRematchEligibilityResponse implements ICheckRematchEligibilityResponse {
  private _json: ICheckRematchEligibilityResponseJSON

  constructor(m?: ICheckRematchEligibilityResponse) {
    this._json = {}
    if (m) {
      this._json['can_rematch'] = m.canRematch
      this._json['eligible_players'] = m.eligiblePlayers
      this._json['notEligiblePlayersReason'] = m.notEligiblePlayersReason
      this._json['rematch_config'] = m.rematchConfig
    }
  }
  
  // canRematch (can_rematch)
  public get canRematch(): boolean {
    return this._json.can_rematch!
  }
  public set canRematch(value: boolean) {
    this._json.can_rematch = value
  }
  
  // eligiblePlayers (eligible_players)
  public get eligiblePlayers(): string[] {
    return this._json.eligible_players || []
  }
  public set eligiblePlayers(value: string[]) {
    this._json.eligible_players = value
  }
  
  // notEligiblePlayersReason (notEligiblePlayersReason)
  public get notEligiblePlayersReason(): CheckRematchEligibilityResponse_NotEligiblePlayersReasonEntry[] {
    return this._json.notEligiblePlayersReason || []
  }
  public set notEligiblePlayersReason(value: CheckRematchEligibilityResponse_NotEligiblePlayersReasonEntry[]) {
    this._json.notEligiblePlayersReason = value
  }
  
  // rematchConfig (rematch_config)
  public get rematchConfig(): RematchConfig {
    return this._json.rematch_config!
  }
  public set rematchConfig(value: RematchConfig) {
    this._json.rematch_config = value
  }
  
  static fromJSON(m: ICheckRematchEligibilityResponseJSON = {}): CheckRematchEligibilityResponse {
    return new CheckRematchEligibilityResponse({
      canRematch: m['can_rematch']!,
      eligiblePlayers: (m['eligible_players']! || []).map((v) => { return String(v)}),
      notEligiblePlayersReason: (m['notEligiblePlayersReason']! || []).map((v) => { return CheckRematchEligibilityResponse_NotEligiblePlayersReasonEntry.fromJSON(v) }),
      rematchConfig: RematchConfig.fromJSON(m['rematch_config']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IVerifyPlayerPoolAndCreateRematchRoomRequest {
  houseId?: string
  tourId?: string
  playerIds?: string[]
  timestamp?: string
  
  toJSON?(): object
}

export interface IVerifyPlayerPoolAndCreateRematchRoomRequestJSON {
  house_id?: string
  tour_id?: string
  player_ids?: string[]
  timestamp?: string
  toJSON?(): object
}

export class VerifyPlayerPoolAndCreateRematchRoomRequest implements IVerifyPlayerPoolAndCreateRematchRoomRequest {
  private _json: IVerifyPlayerPoolAndCreateRematchRoomRequestJSON

  constructor(m?: IVerifyPlayerPoolAndCreateRematchRoomRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['player_ids'] = m.playerIds
      this._json['timestamp'] = m.timestamp
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // playerIds (player_ids)
  public get playerIds(): string[] {
    return this._json.player_ids || []
  }
  public set playerIds(value: string[]) {
    this._json.player_ids = value
  }
  
  // timestamp (timestamp)
  public get timestamp(): string {
    return this._json.timestamp!
  }
  public set timestamp(value: string) {
    this._json.timestamp = value
  }
  
  static fromJSON(m: IVerifyPlayerPoolAndCreateRematchRoomRequestJSON = {}): VerifyPlayerPoolAndCreateRematchRoomRequest {
    return new VerifyPlayerPoolAndCreateRematchRoomRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      playerIds: (m['player_ids']! || []).map((v) => { return String(v)}),
      timestamp: m['timestamp']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IVerifyPlayerPoolAndCreateRematchRoomResponse {
  roomId?: string
  shouldStartGame?: boolean
  playersValidationInfo?: PlayerValidationInfo[]
  contestConfig?: ContestConfig
  gameConfig?: string
  isRematchEnabled?: boolean
  roundInfo?: RoundInfo
  
  toJSON?(): object
}

export interface IVerifyPlayerPoolAndCreateRematchRoomResponseJSON {
  room_id?: string
  should_start_game?: boolean
  players_validation_info?: PlayerValidationInfo[]
  contest_config?: ContestConfig
  game_config?: string
  is_rematch_enabled?: boolean
  round_info?: RoundInfo
  toJSON?(): object
}

export class VerifyPlayerPoolAndCreateRematchRoomResponse implements IVerifyPlayerPoolAndCreateRematchRoomResponse {
  private _json: IVerifyPlayerPoolAndCreateRematchRoomResponseJSON

  constructor(m?: IVerifyPlayerPoolAndCreateRematchRoomResponse) {
    this._json = {}
    if (m) {
      this._json['room_id'] = m.roomId
      this._json['should_start_game'] = m.shouldStartGame
      this._json['players_validation_info'] = m.playersValidationInfo
      this._json['contest_config'] = m.contestConfig
      this._json['game_config'] = m.gameConfig
      this._json['is_rematch_enabled'] = m.isRematchEnabled
      this._json['round_info'] = m.roundInfo
    }
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // shouldStartGame (should_start_game)
  public get shouldStartGame(): boolean {
    return this._json.should_start_game!
  }
  public set shouldStartGame(value: boolean) {
    this._json.should_start_game = value
  }
  
  // playersValidationInfo (players_validation_info)
  public get playersValidationInfo(): PlayerValidationInfo[] {
    return this._json.players_validation_info || []
  }
  public set playersValidationInfo(value: PlayerValidationInfo[]) {
    this._json.players_validation_info = value
  }
  
  // contestConfig (contest_config)
  public get contestConfig(): ContestConfig {
    return this._json.contest_config!
  }
  public set contestConfig(value: ContestConfig) {
    this._json.contest_config = value
  }
  
  // gameConfig (game_config)
  public get gameConfig(): string {
    return this._json.game_config!
  }
  public set gameConfig(value: string) {
    this._json.game_config = value
  }
  
  // isRematchEnabled (is_rematch_enabled)
  public get isRematchEnabled(): boolean {
    return this._json.is_rematch_enabled!
  }
  public set isRematchEnabled(value: boolean) {
    this._json.is_rematch_enabled = value
  }
  
  // roundInfo (round_info)
  public get roundInfo(): RoundInfo {
    return this._json.round_info!
  }
  public set roundInfo(value: RoundInfo) {
    this._json.round_info = value
  }
  
  static fromJSON(m: IVerifyPlayerPoolAndCreateRematchRoomResponseJSON = {}): VerifyPlayerPoolAndCreateRematchRoomResponse {
    return new VerifyPlayerPoolAndCreateRematchRoomResponse({
      roomId: m['room_id']!,
      shouldStartGame: m['should_start_game']!,
      playersValidationInfo: (m['players_validation_info']! || []).map((v) => { return PlayerValidationInfo.fromJSON(v) }),
      contestConfig: ContestConfig.fromJSON(m['contest_config']!),
      gameConfig: m['game_config']!,
      isRematchEnabled: m['is_rematch_enabled']!,
      roundInfo: RoundInfo.fromJSON(m['round_info']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISessionMatchInfo {
  sessionId?: string
  playerId?: string
  
  toJSON?(): object
}

export interface ISessionMatchInfoJSON {
  session_id?: string
  player_id?: string
  toJSON?(): object
}

export class SessionMatchInfo implements ISessionMatchInfo {
  private _json: ISessionMatchInfoJSON

  constructor(m?: ISessionMatchInfo) {
    this._json = {}
    if (m) {
      this._json['session_id'] = m.sessionId
      this._json['player_id'] = m.playerId
    }
  }
  
  // sessionId (session_id)
  public get sessionId(): string {
    return this._json.session_id!
  }
  public set sessionId(value: string) {
    this._json.session_id = value
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  static fromJSON(m: ISessionMatchInfoJSON = {}): SessionMatchInfo {
    return new SessionMatchInfo({
      sessionId: m['session_id']!,
      playerId: m['player_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateSessionWithMatchIdRequest {
  houseId?: string
  tourId?: string
  roomId?: string
  matchId?: string
  roundNumber?: number
  
  toJSON?(): object
}

export interface IUpdateSessionWithMatchIdRequestJSON {
  house_id?: string
  tour_id?: string
  room_id?: string
  match_id?: string
  round_number?: number
  toJSON?(): object
}

export class UpdateSessionWithMatchIdRequest implements IUpdateSessionWithMatchIdRequest {
  private _json: IUpdateSessionWithMatchIdRequestJSON

  constructor(m?: IUpdateSessionWithMatchIdRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['tour_id'] = m.tourId
      this._json['room_id'] = m.roomId
      this._json['match_id'] = m.matchId
      this._json['round_number'] = m.roundNumber
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // tourId (tour_id)
  public get tourId(): string {
    return this._json.tour_id!
  }
  public set tourId(value: string) {
    this._json.tour_id = value
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // matchId (match_id)
  public get matchId(): string {
    return this._json.match_id!
  }
  public set matchId(value: string) {
    this._json.match_id = value
  }
  
  // roundNumber (round_number)
  public get roundNumber(): number {
    return this._json.round_number!
  }
  public set roundNumber(value: number) {
    this._json.round_number = value
  }
  
  static fromJSON(m: IUpdateSessionWithMatchIdRequestJSON = {}): UpdateSessionWithMatchIdRequest {
    return new UpdateSessionWithMatchIdRequest({
      houseId: m['house_id']!,
      tourId: m['tour_id']!,
      roomId: m['room_id']!,
      matchId: m['match_id']!,
      roundNumber: m['round_number']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateSessionWithMatchIdResponse {
  toJSON?(): object
}

export interface IUpdateSessionWithMatchIdResponseJSON {
  toJSON?(): object
}

export class UpdateSessionWithMatchIdResponse implements IUpdateSessionWithMatchIdResponse {
  private _json: IUpdateSessionWithMatchIdResponseJSON

  constructor(m?: IUpdateSessionWithMatchIdResponse) {
    this._json = {}
    if (m) {
    }
  }
  
  static fromJSON(m: IUpdateSessionWithMatchIdResponseJSON = {}): UpdateSessionWithMatchIdResponse {
    return new UpdateSessionWithMatchIdResponse({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGetSessionInfoForRoundRequest {
  houseId?: string
  roomId?: string
  playerIds?: string[]
  roundNumber?: number
  
  toJSON?(): object
}

export interface IGetSessionInfoForRoundRequestJSON {
  house_id?: string
  room_id?: string
  player_ids?: string[]
  round_number?: number
  toJSON?(): object
}

export class GetSessionInfoForRoundRequest implements IGetSessionInfoForRoundRequest {
  private _json: IGetSessionInfoForRoundRequestJSON

  constructor(m?: IGetSessionInfoForRoundRequest) {
    this._json = {}
    if (m) {
      this._json['house_id'] = m.houseId
      this._json['room_id'] = m.roomId
      this._json['player_ids'] = m.playerIds
      this._json['round_number'] = m.roundNumber
    }
  }
  
  // houseId (house_id)
  public get houseId(): string {
    return this._json.house_id!
  }
  public set houseId(value: string) {
    this._json.house_id = value
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // playerIds (player_ids)
  public get playerIds(): string[] {
    return this._json.player_ids || []
  }
  public set playerIds(value: string[]) {
    this._json.player_ids = value
  }
  
  // roundNumber (round_number)
  public get roundNumber(): number {
    return this._json.round_number!
  }
  public set roundNumber(value: number) {
    this._json.round_number = value
  }
  
  static fromJSON(m: IGetSessionInfoForRoundRequestJSON = {}): GetSessionInfoForRoundRequest {
    return new GetSessionInfoForRoundRequest({
      houseId: m['house_id']!,
      roomId: m['room_id']!,
      playerIds: (m['player_ids']! || []).map((v) => { return String(v)}),
      roundNumber: m['round_number']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGetSessionInfoForRoundResponse {
  roomId?: string
  shouldStartGame?: boolean
  playersValidationInfo?: PlayerValidationInfo[]
  contestConfig?: ContestConfig
  gameConfig?: string
  isRematchEnabled?: boolean
  roundInfo?: RoundInfo
  
  toJSON?(): object
}

export interface IGetSessionInfoForRoundResponseJSON {
  room_id?: string
  should_start_game?: boolean
  players_validation_info?: PlayerValidationInfo[]
  contest_config?: ContestConfig
  game_config?: string
  is_rematch_enabled?: boolean
  round_info?: RoundInfo
  toJSON?(): object
}

export class GetSessionInfoForRoundResponse implements IGetSessionInfoForRoundResponse {
  private _json: IGetSessionInfoForRoundResponseJSON

  constructor(m?: IGetSessionInfoForRoundResponse) {
    this._json = {}
    if (m) {
      this._json['room_id'] = m.roomId
      this._json['should_start_game'] = m.shouldStartGame
      this._json['players_validation_info'] = m.playersValidationInfo
      this._json['contest_config'] = m.contestConfig
      this._json['game_config'] = m.gameConfig
      this._json['is_rematch_enabled'] = m.isRematchEnabled
      this._json['round_info'] = m.roundInfo
    }
  }
  
  // roomId (room_id)
  public get roomId(): string {
    return this._json.room_id!
  }
  public set roomId(value: string) {
    this._json.room_id = value
  }
  
  // shouldStartGame (should_start_game)
  public get shouldStartGame(): boolean {
    return this._json.should_start_game!
  }
  public set shouldStartGame(value: boolean) {
    this._json.should_start_game = value
  }
  
  // playersValidationInfo (players_validation_info)
  public get playersValidationInfo(): PlayerValidationInfo[] {
    return this._json.players_validation_info || []
  }
  public set playersValidationInfo(value: PlayerValidationInfo[]) {
    this._json.players_validation_info = value
  }
  
  // contestConfig (contest_config)
  public get contestConfig(): ContestConfig {
    return this._json.contest_config!
  }
  public set contestConfig(value: ContestConfig) {
    this._json.contest_config = value
  }
  
  // gameConfig (game_config)
  public get gameConfig(): string {
    return this._json.game_config!
  }
  public set gameConfig(value: string) {
    this._json.game_config = value
  }
  
  // isRematchEnabled (is_rematch_enabled)
  public get isRematchEnabled(): boolean {
    return this._json.is_rematch_enabled!
  }
  public set isRematchEnabled(value: boolean) {
    this._json.is_rematch_enabled = value
  }
  
  // roundInfo (round_info)
  public get roundInfo(): RoundInfo {
    return this._json.round_info!
  }
  public set roundInfo(value: RoundInfo) {
    this._json.round_info = value
  }
  
  static fromJSON(m: IGetSessionInfoForRoundResponseJSON = {}): GetSessionInfoForRoundResponse {
    return new GetSessionInfoForRoundResponse({
      roomId: m['room_id']!,
      shouldStartGame: m['should_start_game']!,
      playersValidationInfo: (m['players_validation_info']! || []).map((v) => { return PlayerValidationInfo.fromJSON(v) }),
      contestConfig: ContestConfig.fromJSON(m['contest_config']!),
      gameConfig: m['game_config']!,
      isRematchEnabled: m['is_rematch_enabled']!,
      roundInfo: RoundInfo.fromJSON(m['round_info']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateLeaderBoardRequest {
  title?: string
  startTime?: string
  endTime?: string
  infoSource?: string
  Operator?: OperatorType
  joinRequired?: boolean
  lookupId?: string
  metadata?: CreateLeaderBoardRequest_MetadataEntry[]
  preconditions?: CreateLeaderBoardRequest_PreconditionsEntry[]
  scoreFieldName?: string
  description?: string
  expiryTime?: string
  
  toJSON?(): object
}

export interface ICreateLeaderBoardRequestJSON {
  title?: string
  start_time?: string
  end_time?: string
  info_source?: string
  Operator?: OperatorType
  join_required?: boolean
  lookup_id?: string
  metadata?: CreateLeaderBoardRequest_MetadataEntry[]
  preconditions?: CreateLeaderBoardRequest_PreconditionsEntry[]
  score_field_name?: string
  description?: string
  expiry_time?: string
  toJSON?(): object
}

export class CreateLeaderBoardRequest implements ICreateLeaderBoardRequest {
  private _json: ICreateLeaderBoardRequestJSON

  constructor(m?: ICreateLeaderBoardRequest) {
    this._json = {}
    if (m) {
      this._json['title'] = m.title
      this._json['start_time'] = m.startTime
      this._json['end_time'] = m.endTime
      this._json['info_source'] = m.infoSource
      this._json['Operator'] = m.Operator
      this._json['join_required'] = m.joinRequired
      this._json['lookup_id'] = m.lookupId
      this._json['metadata'] = m.metadata
      this._json['preconditions'] = m.preconditions
      this._json['score_field_name'] = m.scoreFieldName
      this._json['description'] = m.description
      this._json['expiry_time'] = m.expiryTime
    }
  }
  
  // title (title)
  public get title(): string {
    return this._json.title!
  }
  public set title(value: string) {
    this._json.title = value
  }
  
  // startTime (start_time)
  public get startTime(): string {
    return this._json.start_time!
  }
  public set startTime(value: string) {
    this._json.start_time = value
  }
  
  // endTime (end_time)
  public get endTime(): string {
    return this._json.end_time!
  }
  public set endTime(value: string) {
    this._json.end_time = value
  }
  
  // infoSource (info_source)
  public get infoSource(): string {
    return this._json.info_source!
  }
  public set infoSource(value: string) {
    this._json.info_source = value
  }
  
  // Operator (Operator)
  public get Operator(): OperatorType {
    return (<any>OperatorType)[this._json.Operator!]
  }
  public set Operator(value: OperatorType) {
    this._json.Operator = value
  }
  
  // joinRequired (join_required)
  public get joinRequired(): boolean {
    return this._json.join_required!
  }
  public set joinRequired(value: boolean) {
    this._json.join_required = value
  }
  
  // lookupId (lookup_id)
  public get lookupId(): string {
    return this._json.lookup_id!
  }
  public set lookupId(value: string) {
    this._json.lookup_id = value
  }
  
  // metadata (metadata)
  public get metadata(): CreateLeaderBoardRequest_MetadataEntry[] {
    return this._json.metadata || []
  }
  public set metadata(value: CreateLeaderBoardRequest_MetadataEntry[]) {
    this._json.metadata = value
  }
  
  // preconditions (preconditions)
  public get preconditions(): CreateLeaderBoardRequest_PreconditionsEntry[] {
    return this._json.preconditions || []
  }
  public set preconditions(value: CreateLeaderBoardRequest_PreconditionsEntry[]) {
    this._json.preconditions = value
  }
  
  // scoreFieldName (score_field_name)
  public get scoreFieldName(): string {
    return this._json.score_field_name!
  }
  public set scoreFieldName(value: string) {
    this._json.score_field_name = value
  }
  
  // description (description)
  public get description(): string {
    return this._json.description!
  }
  public set description(value: string) {
    this._json.description = value
  }
  
  // expiryTime (expiry_time)
  public get expiryTime(): string {
    return this._json.expiry_time!
  }
  public set expiryTime(value: string) {
    this._json.expiry_time = value
  }
  
  static fromJSON(m: ICreateLeaderBoardRequestJSON = {}): CreateLeaderBoardRequest {
    return new CreateLeaderBoardRequest({
      title: m['title']!,
      startTime: m['start_time']!,
      endTime: m['end_time']!,
      infoSource: m['info_source']!,
      Operator: (<any>OperatorType)[m['Operator']!]!,
      joinRequired: m['join_required']!,
      lookupId: m['lookup_id']!,
      metadata: (m['metadata']! || []).map((v) => { return CreateLeaderBoardRequest_MetadataEntry.fromJSON(v) }),
      preconditions: (m['preconditions']! || []).map((v) => { return CreateLeaderBoardRequest_PreconditionsEntry.fromJSON(v) }),
      scoreFieldName: m['score_field_name']!,
      description: m['description']!,
      expiryTime: m['expiry_time']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateLeaderBoardResponse {
  leaderboardId?: string
  
  toJSON?(): object
}

export interface ICreateLeaderBoardResponseJSON {
  leaderboard_id?: string
  toJSON?(): object
}

export class CreateLeaderBoardResponse implements ICreateLeaderBoardResponse {
  private _json: ICreateLeaderBoardResponseJSON

  constructor(m?: ICreateLeaderBoardResponse) {
    this._json = {}
    if (m) {
      this._json['leaderboard_id'] = m.leaderboardId
    }
  }
  
  // leaderboardId (leaderboard_id)
  public get leaderboardId(): string {
    return this._json.leaderboard_id!
  }
  public set leaderboardId(value: string) {
    this._json.leaderboard_id = value
  }
  
  static fromJSON(m: ICreateLeaderBoardResponseJSON = {}): CreateLeaderBoardResponse {
    return new CreateLeaderBoardResponse({
      leaderboardId: m['leaderboard_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IFulfillOrderRequest {
  orderRef?: string
  authKey?: string
  
  toJSON?(): object
}

export interface IFulfillOrderRequestJSON {
  order_ref?: string
  auth_key?: string
  toJSON?(): object
}

export class FulfillOrderRequest implements IFulfillOrderRequest {
  private _json: IFulfillOrderRequestJSON

  constructor(m?: IFulfillOrderRequest) {
    this._json = {}
    if (m) {
      this._json['order_ref'] = m.orderRef
      this._json['auth_key'] = m.authKey
    }
  }
  
  // orderRef (order_ref)
  public get orderRef(): string {
    return this._json.order_ref!
  }
  public set orderRef(value: string) {
    this._json.order_ref = value
  }
  
  // authKey (auth_key)
  public get authKey(): string {
    return this._json.auth_key!
  }
  public set authKey(value: string) {
    this._json.auth_key = value
  }
  
  static fromJSON(m: IFulfillOrderRequestJSON = {}): FulfillOrderRequest {
    return new FulfillOrderRequest({
      orderRef: m['order_ref']!,
      authKey: m['auth_key']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IProcessPaymentRequest {
  orderRef?: string
  playerId?: string
  authKey?: string
  
  toJSON?(): object
}

export interface IProcessPaymentRequestJSON {
  order_ref?: string
  player_id?: string
  auth_key?: string
  toJSON?(): object
}

export class ProcessPaymentRequest implements IProcessPaymentRequest {
  private _json: IProcessPaymentRequestJSON

  constructor(m?: IProcessPaymentRequest) {
    this._json = {}
    if (m) {
      this._json['order_ref'] = m.orderRef
      this._json['player_id'] = m.playerId
      this._json['auth_key'] = m.authKey
    }
  }
  
  // orderRef (order_ref)
  public get orderRef(): string {
    return this._json.order_ref!
  }
  public set orderRef(value: string) {
    this._json.order_ref = value
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // authKey (auth_key)
  public get authKey(): string {
    return this._json.auth_key!
  }
  public set authKey(value: string) {
    this._json.auth_key = value
  }
  
  static fromJSON(m: IProcessPaymentRequestJSON = {}): ProcessPaymentRequest {
    return new ProcessPaymentRequest({
      orderRef: m['order_ref']!,
      playerId: m['player_id']!,
      authKey: m['auth_key']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IProcessGemsOrderResponse {
  orderSuccess?: boolean
  
  toJSON?(): object
}

export interface IProcessGemsOrderResponseJSON {
  order_success?: boolean
  toJSON?(): object
}

export class ProcessGemsOrderResponse implements IProcessGemsOrderResponse {
  private _json: IProcessGemsOrderResponseJSON

  constructor(m?: IProcessGemsOrderResponse) {
    this._json = {}
    if (m) {
      this._json['order_success'] = m.orderSuccess
    }
  }
  
  // orderSuccess (order_success)
  public get orderSuccess(): boolean {
    return this._json.order_success!
  }
  public set orderSuccess(value: boolean) {
    this._json.order_success = value
  }
  
  static fromJSON(m: IProcessGemsOrderResponseJSON = {}): ProcessGemsOrderResponse {
    return new ProcessGemsOrderResponse({
      orderSuccess: m['order_success']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IFailOrderRequest {
  orderRef?: string
  authKey?: string
  
  toJSON?(): object
}

export interface IFailOrderRequestJSON {
  order_ref?: string
  auth_key?: string
  toJSON?(): object
}

export class FailOrderRequest implements IFailOrderRequest {
  private _json: IFailOrderRequestJSON

  constructor(m?: IFailOrderRequest) {
    this._json = {}
    if (m) {
      this._json['order_ref'] = m.orderRef
      this._json['auth_key'] = m.authKey
    }
  }
  
  // orderRef (order_ref)
  public get orderRef(): string {
    return this._json.order_ref!
  }
  public set orderRef(value: string) {
    this._json.order_ref = value
  }
  
  // authKey (auth_key)
  public get authKey(): string {
    return this._json.auth_key!
  }
  public set authKey(value: string) {
    this._json.auth_key = value
  }
  
  static fromJSON(m: IFailOrderRequestJSON = {}): FailOrderRequest {
    return new FailOrderRequest({
      orderRef: m['order_ref']!,
      authKey: m['auth_key']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPubsubMessage {
  subscription?: string
  message?: PubsubMessage_Message
  
  toJSON?(): object
}

export interface IPubsubMessageJSON {
  subscription?: string
  message?: PubsubMessage_Message
  toJSON?(): object
}

export class PubsubMessage implements IPubsubMessage {
  private _json: IPubsubMessageJSON

  constructor(m?: IPubsubMessage) {
    this._json = {}
    if (m) {
      this._json['subscription'] = m.subscription
      this._json['message'] = m.message
    }
  }
  
  // subscription (subscription)
  public get subscription(): string {
    return this._json.subscription!
  }
  public set subscription(value: string) {
    this._json.subscription = value
  }
  
  // message (message)
  public get message(): PubsubMessage_Message {
    return this._json.message!
  }
  public set message(value: PubsubMessage_Message) {
    this._json.message = value
  }
  
  static fromJSON(m: IPubsubMessageJSON = {}): PubsubMessage {
    return new PubsubMessage({
      subscription: m['subscription']!,
      message: PubsubMessage_Message.fromJSON(m['message']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IContext {
  conversationId?: number
  locale?: string
  location?: string
  messengerActionColor?: string
  messengerBackgroundColor?: string
  
  toJSON?(): object
}

export interface IContextJSON {
  conversation_id?: number
  locale?: string
  location?: string
  messenger_action_color?: string
  messenger_background_color?: string
  toJSON?(): object
}

export class Context implements IContext {
  private _json: IContextJSON

  constructor(m?: IContext) {
    this._json = {}
    if (m) {
      this._json['conversation_id'] = m.conversationId
      this._json['locale'] = m.locale
      this._json['location'] = m.location
      this._json['messenger_action_color'] = m.messengerActionColor
      this._json['messenger_background_color'] = m.messengerBackgroundColor
    }
  }
  
  // conversationId (conversation_id)
  public get conversationId(): number {
    return this._json.conversation_id!
  }
  public set conversationId(value: number) {
    this._json.conversation_id = value
  }
  
  // locale (locale)
  public get locale(): string {
    return this._json.locale!
  }
  public set locale(value: string) {
    this._json.locale = value
  }
  
  // location (location)
  public get location(): string {
    return this._json.location!
  }
  public set location(value: string) {
    this._json.location = value
  }
  
  // messengerActionColor (messenger_action_color)
  public get messengerActionColor(): string {
    return this._json.messenger_action_color!
  }
  public set messengerActionColor(value: string) {
    this._json.messenger_action_color = value
  }
  
  // messengerBackgroundColor (messenger_background_color)
  public get messengerBackgroundColor(): string {
    return this._json.messenger_background_color!
  }
  public set messengerBackgroundColor(value: string) {
    this._json.messenger_background_color = value
  }
  
  static fromJSON(m: IContextJSON = {}): Context {
    return new Context({
      conversationId: m['conversation_id']!,
      locale: m['locale']!,
      location: m['location']!,
      messengerActionColor: m['messenger_action_color']!,
      messengerBackgroundColor: m['messenger_background_color']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICsAgentDetails {
  type?: string
  id?: string
  name?: string
  email?: string
  awayModeEnabled?: boolean
  awayModeReassign?: boolean
  teamsIds?: number[]
  
  toJSON?(): object
}

export interface ICsAgentDetailsJSON {
  type?: string
  id?: string
  name?: string
  email?: string
  away_mode_enabled?: boolean
  away_mode_reassign?: boolean
  teams_ids?: number[]
  toJSON?(): object
}

export class CsAgentDetails implements ICsAgentDetails {
  private _json: ICsAgentDetailsJSON

  constructor(m?: ICsAgentDetails) {
    this._json = {}
    if (m) {
      this._json['type'] = m.type
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['email'] = m.email
      this._json['away_mode_enabled'] = m.awayModeEnabled
      this._json['away_mode_reassign'] = m.awayModeReassign
      this._json['teams_ids'] = m.teamsIds
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  // awayModeEnabled (away_mode_enabled)
  public get awayModeEnabled(): boolean {
    return this._json.away_mode_enabled!
  }
  public set awayModeEnabled(value: boolean) {
    this._json.away_mode_enabled = value
  }
  
  // awayModeReassign (away_mode_reassign)
  public get awayModeReassign(): boolean {
    return this._json.away_mode_reassign!
  }
  public set awayModeReassign(value: boolean) {
    this._json.away_mode_reassign = value
  }
  
  // teamsIds (teams_ids)
  public get teamsIds(): number[] {
    return this._json.teams_ids || []
  }
  public set teamsIds(value: number[]) {
    this._json.teams_ids = value
  }
  
  static fromJSON(m: ICsAgentDetailsJSON = {}): CsAgentDetails {
    return new CsAgentDetails({
      type: m['type']!,
      id: m['id']!,
      name: m['name']!,
      email: m['email']!,
      awayModeEnabled: m['away_mode_enabled']!,
      awayModeReassign: m['away_mode_reassign']!,
      teamsIds: (m['teams_ids']! || []).map((v) => { return Number(v)})
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICardCreationOptions {
  playerId?: string
  orderId?: string
  reason?: string
  
  toJSON?(): object
}

export interface ICardCreationOptionsJSON {
  player_id?: string
  order_id?: string
  reason?: string
  toJSON?(): object
}

export class CardCreationOptions implements ICardCreationOptions {
  private _json: ICardCreationOptionsJSON

  constructor(m?: ICardCreationOptions) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['order_id'] = m.orderId
      this._json['reason'] = m.reason
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // orderId (order_id)
  public get orderId(): string {
    return this._json.order_id!
  }
  public set orderId(value: string) {
    this._json.order_id = value
  }
  
  // reason (reason)
  public get reason(): string {
    return this._json.reason!
  }
  public set reason(value: string) {
    this._json.reason = value
  }
  
  static fromJSON(m: ICardCreationOptionsJSON = {}): CardCreationOptions {
    return new CardCreationOptions({
      playerId: m['player_id']!,
      orderId: m['order_id']!,
      reason: m['reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IAction {
  type?: string
  
  toJSON?(): object
}

export interface IActionJSON {
  type?: string
  toJSON?(): object
}

export class Action implements IAction {
  private _json: IActionJSON

  constructor(m?: IAction) {
    this._json = {}
    if (m) {
      this._json['type'] = m.type
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  static fromJSON(m: IActionJSON = {}): Action {
    return new Action({
      type: m['type']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IComponent {
  type?: string
  label?: string
  value?: string
  style?: string
  id?: string
  action?: Action
  align?: string
  text?: string
  
  toJSON?(): object
}

export interface IComponentJSON {
  type?: string
  label?: string
  value?: string
  style?: string
  id?: string
  action?: Action
  align?: string
  text?: string
  toJSON?(): object
}

export class Component implements IComponent {
  private _json: IComponentJSON

  constructor(m?: IComponent) {
    this._json = {}
    if (m) {
      this._json['type'] = m.type
      this._json['label'] = m.label
      this._json['value'] = m.value
      this._json['style'] = m.style
      this._json['id'] = m.id
      this._json['action'] = m.action
      this._json['align'] = m.align
      this._json['text'] = m.text
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // label (label)
  public get label(): string {
    return this._json.label!
  }
  public set label(value: string) {
    this._json.label = value
  }
  
  // value (value)
  public get value(): string {
    return this._json.value!
  }
  public set value(value: string) {
    this._json.value = value
  }
  
  // style (style)
  public get style(): string {
    return this._json.style!
  }
  public set style(value: string) {
    this._json.style = value
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // action (action)
  public get action(): Action {
    return this._json.action!
  }
  public set action(value: Action) {
    this._json.action = value
  }
  
  // align (align)
  public get align(): string {
    return this._json.align!
  }
  public set align(value: string) {
    this._json.align = value
  }
  
  // text (text)
  public get text(): string {
    return this._json.text!
  }
  public set text(value: string) {
    this._json.text = value
  }
  
  static fromJSON(m: IComponentJSON = {}): Component {
    return new Component({
      type: m['type']!,
      label: m['label']!,
      value: m['value']!,
      style: m['style']!,
      id: m['id']!,
      action: Action.fromJSON(m['action']!),
      align: m['align']!,
      text: m['text']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IStoredData {
  orderId?: string
  refundIds?: string[]
  
  toJSON?(): object
}

export interface IStoredDataJSON {
  order_id?: string
  refund_ids?: string[]
  toJSON?(): object
}

export class StoredData implements IStoredData {
  private _json: IStoredDataJSON

  constructor(m?: IStoredData) {
    this._json = {}
    if (m) {
      this._json['order_id'] = m.orderId
      this._json['refund_ids'] = m.refundIds
    }
  }
  
  // orderId (order_id)
  public get orderId(): string {
    return this._json.order_id!
  }
  public set orderId(value: string) {
    this._json.order_id = value
  }
  
  // refundIds (refund_ids)
  public get refundIds(): string[] {
    return this._json.refund_ids || []
  }
  public set refundIds(value: string[]) {
    this._json.refund_ids = value
  }
  
  static fromJSON(m: IStoredDataJSON = {}): StoredData {
    return new StoredData({
      orderId: m['order_id']!,
      refundIds: (m['refund_ids']! || []).map((v) => { return String(v)})
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IContent {
  components?: Component[]
  
  toJSON?(): object
}

export interface IContentJSON {
  components?: Component[]
  toJSON?(): object
}

export class Content implements IContent {
  private _json: IContentJSON

  constructor(m?: IContent) {
    this._json = {}
    if (m) {
      this._json['components'] = m.components
    }
  }
  
  // components (components)
  public get components(): Component[] {
    return this._json.components || []
  }
  public set components(value: Component[]) {
    this._json.components = value
  }
  
  static fromJSON(m: IContentJSON = {}): Content {
    return new Content({
      components: (m['components']! || []).map((v) => { return Component.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICanvas {
  content?: Content
  storedData?: StoredData
  
  toJSON?(): object
}

export interface ICanvasJSON {
  content?: Content
  stored_data?: StoredData
  toJSON?(): object
}

export class Canvas implements ICanvas {
  private _json: ICanvasJSON

  constructor(m?: ICanvas) {
    this._json = {}
    if (m) {
      this._json['content'] = m.content
      this._json['stored_data'] = m.storedData
    }
  }
  
  // content (content)
  public get content(): Content {
    return this._json.content!
  }
  public set content(value: Content) {
    this._json.content = value
  }
  
  // storedData (stored_data)
  public get storedData(): StoredData {
    return this._json.stored_data!
  }
  public set storedData(value: StoredData) {
    this._json.stored_data = value
  }
  
  static fromJSON(m: ICanvasJSON = {}): Canvas {
    return new Canvas({
      content: Content.fromJSON(m['content']!),
      storedData: StoredData.fromJSON(m['stored_data']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ILocationData {
  type?: string
  cityName?: string
  continentCode?: string
  countryName?: string
  postalCode?: string
  regionName?: string
  timezone?: string
  countryCode?: string
  
  toJSON?(): object
}

export interface ILocationDataJSON {
  type?: string
  city_name?: string
  continent_code?: string
  country_name?: string
  postal_code?: string
  region_name?: string
  timezone?: string
  country_code?: string
  toJSON?(): object
}

export class LocationData implements ILocationData {
  private _json: ILocationDataJSON

  constructor(m?: ILocationData) {
    this._json = {}
    if (m) {
      this._json['type'] = m.type
      this._json['city_name'] = m.cityName
      this._json['continent_code'] = m.continentCode
      this._json['country_name'] = m.countryName
      this._json['postal_code'] = m.postalCode
      this._json['region_name'] = m.regionName
      this._json['timezone'] = m.timezone
      this._json['country_code'] = m.countryCode
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // cityName (city_name)
  public get cityName(): string {
    return this._json.city_name!
  }
  public set cityName(value: string) {
    this._json.city_name = value
  }
  
  // continentCode (continent_code)
  public get continentCode(): string {
    return this._json.continent_code!
  }
  public set continentCode(value: string) {
    this._json.continent_code = value
  }
  
  // countryName (country_name)
  public get countryName(): string {
    return this._json.country_name!
  }
  public set countryName(value: string) {
    this._json.country_name = value
  }
  
  // postalCode (postal_code)
  public get postalCode(): string {
    return this._json.postal_code!
  }
  public set postalCode(value: string) {
    this._json.postal_code = value
  }
  
  // regionName (region_name)
  public get regionName(): string {
    return this._json.region_name!
  }
  public set regionName(value: string) {
    this._json.region_name = value
  }
  
  // timezone (timezone)
  public get timezone(): string {
    return this._json.timezone!
  }
  public set timezone(value: string) {
    this._json.timezone = value
  }
  
  // countryCode (country_code)
  public get countryCode(): string {
    return this._json.country_code!
  }
  public set countryCode(value: string) {
    this._json.country_code = value
  }
  
  static fromJSON(m: ILocationDataJSON = {}): LocationData {
    return new LocationData({
      type: m['type']!,
      cityName: m['city_name']!,
      continentCode: m['continent_code']!,
      countryName: m['country_name']!,
      postalCode: m['postal_code']!,
      regionName: m['region_name']!,
      timezone: m['timezone']!,
      countryCode: m['country_code']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUser {
  type?: string
  id?: string
  userId?: string
  anonymous?: boolean
  email?: string
  phone?: string
  name?: string
  appId?: string
  locationData?: LocationData
  lastRequestAt?: string
  createdAt?: string
  remoteCreatedAt?: string
  signedUpAt?: string
  updatedAt?: string
  sessionCount?: number
  
  toJSON?(): object
}

export interface IUserJSON {
  type?: string
  id?: string
  user_id?: string
  anonymous?: boolean
  email?: string
  phone?: string
  name?: string
  app_id?: string
  location_data?: LocationData
  last_request_at?: string
  created_at?: string
  remote_created_at?: string
  signed_up_at?: string
  updated_at?: string
  session_count?: number
  toJSON?(): object
}

export class User implements IUser {
  private _json: IUserJSON

  constructor(m?: IUser) {
    this._json = {}
    if (m) {
      this._json['type'] = m.type
      this._json['id'] = m.id
      this._json['user_id'] = m.userId
      this._json['anonymous'] = m.anonymous
      this._json['email'] = m.email
      this._json['phone'] = m.phone
      this._json['name'] = m.name
      this._json['app_id'] = m.appId
      this._json['location_data'] = m.locationData
      this._json['last_request_at'] = m.lastRequestAt
      this._json['created_at'] = m.createdAt
      this._json['remote_created_at'] = m.remoteCreatedAt
      this._json['signed_up_at'] = m.signedUpAt
      this._json['updated_at'] = m.updatedAt
      this._json['session_count'] = m.sessionCount
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // anonymous (anonymous)
  public get anonymous(): boolean {
    return this._json.anonymous!
  }
  public set anonymous(value: boolean) {
    this._json.anonymous = value
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  // phone (phone)
  public get phone(): string {
    return this._json.phone!
  }
  public set phone(value: string) {
    this._json.phone = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // appId (app_id)
  public get appId(): string {
    return this._json.app_id!
  }
  public set appId(value: string) {
    this._json.app_id = value
  }
  
  // locationData (location_data)
  public get locationData(): LocationData {
    return this._json.location_data!
  }
  public set locationData(value: LocationData) {
    this._json.location_data = value
  }
  
  // lastRequestAt (last_request_at)
  public get lastRequestAt(): string {
    return this._json.last_request_at!
  }
  public set lastRequestAt(value: string) {
    this._json.last_request_at = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // remoteCreatedAt (remote_created_at)
  public get remoteCreatedAt(): string {
    return this._json.remote_created_at!
  }
  public set remoteCreatedAt(value: string) {
    this._json.remote_created_at = value
  }
  
  // signedUpAt (signed_up_at)
  public get signedUpAt(): string {
    return this._json.signed_up_at!
  }
  public set signedUpAt(value: string) {
    this._json.signed_up_at = value
  }
  
  // updatedAt (updated_at)
  public get updatedAt(): string {
    return this._json.updated_at!
  }
  public set updatedAt(value: string) {
    this._json.updated_at = value
  }
  
  // sessionCount (session_count)
  public get sessionCount(): number {
    return this._json.session_count!
  }
  public set sessionCount(value: number) {
    this._json.session_count = value
  }
  
  static fromJSON(m: IUserJSON = {}): User {
    return new User({
      type: m['type']!,
      id: m['id']!,
      userId: m['user_id']!,
      anonymous: m['anonymous']!,
      email: m['email']!,
      phone: m['phone']!,
      name: m['name']!,
      appId: m['app_id']!,
      locationData: LocationData.fromJSON(m['location_data']!),
      lastRequestAt: m['last_request_at']!,
      createdAt: m['created_at']!,
      remoteCreatedAt: m['remote_created_at']!,
      signedUpAt: m['signed_up_at']!,
      updatedAt: m['updated_at']!,
      sessionCount: m['session_count']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRefundInitiateRequest {
  appId?: string
  workspaceId?: string
  cardCreationOptions?: CardCreationOptions
  context?: Context
  admin?: CsAgentDetails
  
  toJSON?(): object
}

export interface IRefundInitiateRequestJSON {
  app_id?: string
  workspace_id?: string
  card_creation_options?: CardCreationOptions
  context?: Context
  admin?: CsAgentDetails
  toJSON?(): object
}

export class RefundInitiateRequest implements IRefundInitiateRequest {
  private _json: IRefundInitiateRequestJSON

  constructor(m?: IRefundInitiateRequest) {
    this._json = {}
    if (m) {
      this._json['app_id'] = m.appId
      this._json['workspace_id'] = m.workspaceId
      this._json['card_creation_options'] = m.cardCreationOptions
      this._json['context'] = m.context
      this._json['admin'] = m.admin
    }
  }
  
  // appId (app_id)
  public get appId(): string {
    return this._json.app_id!
  }
  public set appId(value: string) {
    this._json.app_id = value
  }
  
  // workspaceId (workspace_id)
  public get workspaceId(): string {
    return this._json.workspace_id!
  }
  public set workspaceId(value: string) {
    this._json.workspace_id = value
  }
  
  // cardCreationOptions (card_creation_options)
  public get cardCreationOptions(): CardCreationOptions {
    return this._json.card_creation_options!
  }
  public set cardCreationOptions(value: CardCreationOptions) {
    this._json.card_creation_options = value
  }
  
  // context (context)
  public get context(): Context {
    return this._json.context!
  }
  public set context(value: Context) {
    this._json.context = value
  }
  
  // admin (admin)
  public get admin(): CsAgentDetails {
    return this._json.admin!
  }
  public set admin(value: CsAgentDetails) {
    this._json.admin = value
  }
  
  static fromJSON(m: IRefundInitiateRequestJSON = {}): RefundInitiateRequest {
    return new RefundInitiateRequest({
      appId: m['app_id']!,
      workspaceId: m['workspace_id']!,
      cardCreationOptions: CardCreationOptions.fromJSON(m['card_creation_options']!),
      context: Context.fromJSON(m['context']!),
      admin: CsAgentDetails.fromJSON(m['admin']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRefundInitiateResponse {
  canvas?: Canvas
  
  toJSON?(): object
}

export interface IRefundInitiateResponseJSON {
  canvas?: Canvas
  toJSON?(): object
}

export class RefundInitiateResponse implements IRefundInitiateResponse {
  private _json: IRefundInitiateResponseJSON

  constructor(m?: IRefundInitiateResponse) {
    this._json = {}
    if (m) {
      this._json['canvas'] = m.canvas
    }
  }
  
  // canvas (canvas)
  public get canvas(): Canvas {
    return this._json.canvas!
  }
  public set canvas(value: Canvas) {
    this._json.canvas = value
  }
  
  static fromJSON(m: IRefundInitiateResponseJSON = {}): RefundInitiateResponse {
    return new RefundInitiateResponse({
      canvas: Canvas.fromJSON(m['canvas']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRefundProcessRequest {
  appId?: string
  workspaceId?: string
  currentCanvas?: Canvas
  componentId?: string
  customer?: User
  context?: Context
  user?: User
  
  toJSON?(): object
}

export interface IRefundProcessRequestJSON {
  app_id?: string
  workspace_id?: string
  current_canvas?: Canvas
  component_id?: string
  customer?: User
  context?: Context
  user?: User
  toJSON?(): object
}

export class RefundProcessRequest implements IRefundProcessRequest {
  private _json: IRefundProcessRequestJSON

  constructor(m?: IRefundProcessRequest) {
    this._json = {}
    if (m) {
      this._json['app_id'] = m.appId
      this._json['workspace_id'] = m.workspaceId
      this._json['current_canvas'] = m.currentCanvas
      this._json['component_id'] = m.componentId
      this._json['customer'] = m.customer
      this._json['context'] = m.context
      this._json['user'] = m.user
    }
  }
  
  // appId (app_id)
  public get appId(): string {
    return this._json.app_id!
  }
  public set appId(value: string) {
    this._json.app_id = value
  }
  
  // workspaceId (workspace_id)
  public get workspaceId(): string {
    return this._json.workspace_id!
  }
  public set workspaceId(value: string) {
    this._json.workspace_id = value
  }
  
  // currentCanvas (current_canvas)
  public get currentCanvas(): Canvas {
    return this._json.current_canvas!
  }
  public set currentCanvas(value: Canvas) {
    this._json.current_canvas = value
  }
  
  // componentId (component_id)
  public get componentId(): string {
    return this._json.component_id!
  }
  public set componentId(value: string) {
    this._json.component_id = value
  }
  
  // customer (customer)
  public get customer(): User {
    return this._json.customer!
  }
  public set customer(value: User) {
    this._json.customer = value
  }
  
  // context (context)
  public get context(): Context {
    return this._json.context!
  }
  public set context(value: Context) {
    this._json.context = value
  }
  
  // user (user)
  public get user(): User {
    return this._json.user!
  }
  public set user(value: User) {
    this._json.user = value
  }
  
  static fromJSON(m: IRefundProcessRequestJSON = {}): RefundProcessRequest {
    return new RefundProcessRequest({
      appId: m['app_id']!,
      workspaceId: m['workspace_id']!,
      currentCanvas: Canvas.fromJSON(m['current_canvas']!),
      componentId: m['component_id']!,
      customer: User.fromJSON(m['customer']!),
      context: Context.fromJSON(m['context']!),
      user: User.fromJSON(m['user']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRefundProcessResponse {
  canvas?: Canvas
  
  toJSON?(): object
}

export interface IRefundProcessResponseJSON {
  canvas?: Canvas
  toJSON?(): object
}

export class RefundProcessResponse implements IRefundProcessResponse {
  private _json: IRefundProcessResponseJSON

  constructor(m?: IRefundProcessResponse) {
    this._json = {}
    if (m) {
      this._json['canvas'] = m.canvas
    }
  }
  
  // canvas (canvas)
  public get canvas(): Canvas {
    return this._json.canvas!
  }
  public set canvas(value: Canvas) {
    this._json.canvas = value
  }
  
  static fromJSON(m: IRefundProcessResponseJSON = {}): RefundProcessResponse {
    return new RefundProcessResponse({
      canvas: Canvas.fromJSON(m['canvas']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGiveRewardToPlayerRequest {
  playerId?: string
  productId?: string
  
  toJSON?(): object
}

export interface IGiveRewardToPlayerRequestJSON {
  player_id?: string
  product_id?: string
  toJSON?(): object
}

export class GiveRewardToPlayerRequest implements IGiveRewardToPlayerRequest {
  private _json: IGiveRewardToPlayerRequestJSON

  constructor(m?: IGiveRewardToPlayerRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['product_id'] = m.productId
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // productId (product_id)
  public get productId(): string {
    return this._json.product_id!
  }
  public set productId(value: string) {
    this._json.product_id = value
  }
  
  static fromJSON(m: IGiveRewardToPlayerRequestJSON = {}): GiveRewardToPlayerRequest {
    return new GiveRewardToPlayerRequest({
      playerId: m['player_id']!,
      productId: m['product_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IGiveRewardToPlayerResponse {
  toJSON?(): object
}

export interface IGiveRewardToPlayerResponseJSON {
  toJSON?(): object
}

export class GiveRewardToPlayerResponse implements IGiveRewardToPlayerResponse {
  private _json: IGiveRewardToPlayerResponseJSON

  constructor(m?: IGiveRewardToPlayerResponse) {
    this._json = {}
    if (m) {
    }
  }
  
  static fromJSON(m: IGiveRewardToPlayerResponseJSON = {}): GiveRewardToPlayerResponse {
    return new GiveRewardToPlayerResponse({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRewardCardCreationOptions {
  playerId?: string
  amount?: number
  reason?: string
  
  toJSON?(): object
}

export interface IRewardCardCreationOptionsJSON {
  player_id?: string
  amount?: number
  reason?: string
  toJSON?(): object
}

export class RewardCardCreationOptions implements IRewardCardCreationOptions {
  private _json: IRewardCardCreationOptionsJSON

  constructor(m?: IRewardCardCreationOptions) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['amount'] = m.amount
      this._json['reason'] = m.reason
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // amount (amount)
  public get amount(): number {
    return this._json.amount!
  }
  public set amount(value: number) {
    this._json.amount = value
  }
  
  // reason (reason)
  public get reason(): string {
    return this._json.reason!
  }
  public set reason(value: string) {
    this._json.reason = value
  }
  
  static fromJSON(m: IRewardCardCreationOptionsJSON = {}): RewardCardCreationOptions {
    return new RewardCardCreationOptions({
      playerId: m['player_id']!,
      amount: m['amount']!,
      reason: m['reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRewardInitiateRequest {
  appId?: string
  workspaceId?: string
  cardCreationOptions?: RewardCardCreationOptions
  context?: Context
  admin?: CsAgentDetails
  
  toJSON?(): object
}

export interface IRewardInitiateRequestJSON {
  app_id?: string
  workspace_id?: string
  card_creation_options?: RewardCardCreationOptions
  context?: Context
  admin?: CsAgentDetails
  toJSON?(): object
}

export class RewardInitiateRequest implements IRewardInitiateRequest {
  private _json: IRewardInitiateRequestJSON

  constructor(m?: IRewardInitiateRequest) {
    this._json = {}
    if (m) {
      this._json['app_id'] = m.appId
      this._json['workspace_id'] = m.workspaceId
      this._json['card_creation_options'] = m.cardCreationOptions
      this._json['context'] = m.context
      this._json['admin'] = m.admin
    }
  }
  
  // appId (app_id)
  public get appId(): string {
    return this._json.app_id!
  }
  public set appId(value: string) {
    this._json.app_id = value
  }
  
  // workspaceId (workspace_id)
  public get workspaceId(): string {
    return this._json.workspace_id!
  }
  public set workspaceId(value: string) {
    this._json.workspace_id = value
  }
  
  // cardCreationOptions (card_creation_options)
  public get cardCreationOptions(): RewardCardCreationOptions {
    return this._json.card_creation_options!
  }
  public set cardCreationOptions(value: RewardCardCreationOptions) {
    this._json.card_creation_options = value
  }
  
  // context (context)
  public get context(): Context {
    return this._json.context!
  }
  public set context(value: Context) {
    this._json.context = value
  }
  
  // admin (admin)
  public get admin(): CsAgentDetails {
    return this._json.admin!
  }
  public set admin(value: CsAgentDetails) {
    this._json.admin = value
  }
  
  static fromJSON(m: IRewardInitiateRequestJSON = {}): RewardInitiateRequest {
    return new RewardInitiateRequest({
      appId: m['app_id']!,
      workspaceId: m['workspace_id']!,
      cardCreationOptions: RewardCardCreationOptions.fromJSON(m['card_creation_options']!),
      context: Context.fromJSON(m['context']!),
      admin: CsAgentDetails.fromJSON(m['admin']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRewardInitiateResponse {
  canvas?: Canvas
  
  toJSON?(): object
}

export interface IRewardInitiateResponseJSON {
  canvas?: Canvas
  toJSON?(): object
}

export class RewardInitiateResponse implements IRewardInitiateResponse {
  private _json: IRewardInitiateResponseJSON

  constructor(m?: IRewardInitiateResponse) {
    this._json = {}
    if (m) {
      this._json['canvas'] = m.canvas
    }
  }
  
  // canvas (canvas)
  public get canvas(): Canvas {
    return this._json.canvas!
  }
  public set canvas(value: Canvas) {
    this._json.canvas = value
  }
  
  static fromJSON(m: IRewardInitiateResponseJSON = {}): RewardInitiateResponse {
    return new RewardInitiateResponse({
      canvas: Canvas.fromJSON(m['canvas']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRewardProcessRequest {
  appId?: string
  workspaceId?: string
  currentCanvas?: Canvas
  componentId?: string
  customer?: User
  context?: Context
  user?: User
  
  toJSON?(): object
}

export interface IRewardProcessRequestJSON {
  app_id?: string
  workspace_id?: string
  current_canvas?: Canvas
  component_id?: string
  customer?: User
  context?: Context
  user?: User
  toJSON?(): object
}

export class RewardProcessRequest implements IRewardProcessRequest {
  private _json: IRewardProcessRequestJSON

  constructor(m?: IRewardProcessRequest) {
    this._json = {}
    if (m) {
      this._json['app_id'] = m.appId
      this._json['workspace_id'] = m.workspaceId
      this._json['current_canvas'] = m.currentCanvas
      this._json['component_id'] = m.componentId
      this._json['customer'] = m.customer
      this._json['context'] = m.context
      this._json['user'] = m.user
    }
  }
  
  // appId (app_id)
  public get appId(): string {
    return this._json.app_id!
  }
  public set appId(value: string) {
    this._json.app_id = value
  }
  
  // workspaceId (workspace_id)
  public get workspaceId(): string {
    return this._json.workspace_id!
  }
  public set workspaceId(value: string) {
    this._json.workspace_id = value
  }
  
  // currentCanvas (current_canvas)
  public get currentCanvas(): Canvas {
    return this._json.current_canvas!
  }
  public set currentCanvas(value: Canvas) {
    this._json.current_canvas = value
  }
  
  // componentId (component_id)
  public get componentId(): string {
    return this._json.component_id!
  }
  public set componentId(value: string) {
    this._json.component_id = value
  }
  
  // customer (customer)
  public get customer(): User {
    return this._json.customer!
  }
  public set customer(value: User) {
    this._json.customer = value
  }
  
  // context (context)
  public get context(): Context {
    return this._json.context!
  }
  public set context(value: Context) {
    this._json.context = value
  }
  
  // user (user)
  public get user(): User {
    return this._json.user!
  }
  public set user(value: User) {
    this._json.user = value
  }
  
  static fromJSON(m: IRewardProcessRequestJSON = {}): RewardProcessRequest {
    return new RewardProcessRequest({
      appId: m['app_id']!,
      workspaceId: m['workspace_id']!,
      currentCanvas: Canvas.fromJSON(m['current_canvas']!),
      componentId: m['component_id']!,
      customer: User.fromJSON(m['customer']!),
      context: Context.fromJSON(m['context']!),
      user: User.fromJSON(m['user']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRewardProcessResponse {
  canvas?: Canvas
  
  toJSON?(): object
}

export interface IRewardProcessResponseJSON {
  canvas?: Canvas
  toJSON?(): object
}

export class RewardProcessResponse implements IRewardProcessResponse {
  private _json: IRewardProcessResponseJSON

  constructor(m?: IRewardProcessResponse) {
    this._json = {}
    if (m) {
      this._json['canvas'] = m.canvas
    }
  }
  
  // canvas (canvas)
  public get canvas(): Canvas {
    return this._json.canvas!
  }
  public set canvas(value: Canvas) {
    this._json.canvas = value
  }
  
  static fromJSON(m: IRewardProcessResponseJSON = {}): RewardProcessResponse {
    return new RewardProcessResponse({
      canvas: Canvas.fromJSON(m['canvas']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISchedule {
  id?: string
  name?: string
  number?: number
  days?: Day[]
  spawnWindows?: Window[]
  archived?: boolean
  createdAt?: string
  createdBy?: string
  
  toJSON?(): object
}

export interface IScheduleJSON {
  id?: string
  name?: string
  number?: number
  days?: Day[]
  spawn_windows?: Window[]
  archived?: boolean
  created_at?: string
  created_by?: string
  toJSON?(): object
}

export class Schedule implements ISchedule {
  private _json: IScheduleJSON

  constructor(m?: ISchedule) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['number'] = m.number
      this._json['days'] = m.days
      this._json['spawn_windows'] = m.spawnWindows
      this._json['archived'] = m.archived
      this._json['created_at'] = m.createdAt
      this._json['created_by'] = m.createdBy
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // number (number)
  public get number(): number {
    return this._json.number!
  }
  public set number(value: number) {
    this._json.number = value
  }
  
  // days (days)
  public get days(): Day[] {
    return (<any>[Day])[this._json.days!]
  }
  public set days(value: Day[]) {
    this._json.days = value
  }
  
  // spawnWindows (spawn_windows)
  public get spawnWindows(): Window[] {
    return (<any>[Window])[this._json.spawn_windows!]
  }
  public set spawnWindows(value: Window[]) {
    this._json.spawn_windows = value
  }
  
  // archived (archived)
  public get archived(): boolean {
    return this._json.archived!
  }
  public set archived(value: boolean) {
    this._json.archived = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // createdBy (created_by)
  public get createdBy(): string {
    return this._json.created_by!
  }
  public set createdBy(value: string) {
    this._json.created_by = value
  }
  
  static fromJSON(m: IScheduleJSON = {}): Schedule {
    return new Schedule({
      id: m['id']!,
      name: m['name']!,
      number: m['number']!,
      days: (m['days']! || []).map((v) => { return Day.fromJSON(v) }),
      spawnWindows: (m['spawn_windows']! || []).map((v) => { return Window.fromJSON(v) }),
      archived: m['archived']!,
      createdAt: m['created_at']!,
      createdBy: m['created_by']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITag {
  id?: string
  attribute?: string
  displayName?: string
  name?: string
  start?: number
  end?: number
  targetPoint?: number
  
  toJSON?(): object
}

export interface ITagJSON {
  id?: string
  attribute?: string
  display_name?: string
  name?: string
  start?: number
  end?: number
  target_point?: number
  toJSON?(): object
}

export class Tag implements ITag {
  private _json: ITagJSON

  constructor(m?: ITag) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['attribute'] = m.attribute
      this._json['display_name'] = m.displayName
      this._json['name'] = m.name
      this._json['start'] = m.start
      this._json['end'] = m.end
      this._json['target_point'] = m.targetPoint
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // attribute (attribute)
  public get attribute(): string {
    return this._json.attribute!
  }
  public set attribute(value: string) {
    this._json.attribute = value
  }
  
  // displayName (display_name)
  public get displayName(): string {
    return this._json.display_name!
  }
  public set displayName(value: string) {
    this._json.display_name = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // start (start)
  public get start(): number {
    return this._json.start!
  }
  public set start(value: number) {
    this._json.start = value
  }
  
  // end (end)
  public get end(): number {
    return this._json.end!
  }
  public set end(value: number) {
    this._json.end = value
  }
  
  // targetPoint (target_point)
  public get targetPoint(): number {
    return this._json.target_point!
  }
  public set targetPoint(value: number) {
    this._json.target_point = value
  }
  
  static fromJSON(m: ITagJSON = {}): Tag {
    return new Tag({
      id: m['id']!,
      attribute: m['attribute']!,
      displayName: m['display_name']!,
      name: m['name']!,
      start: m['start']!,
      end: m['end']!,
      targetPoint: m['target_point']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITemplate {
  id?: string
  name?: string
  block?: Block
  schedule?: Schedule
  blockId?: string
  scheduleId?: string
  gameId?: string
  gameName?: string
  offsetMins?: number
  startTime?: string
  endTime?: string
  createdAt?: string
  createdBy?: string
  perSessionDurationSecs?: number
  templateTags?: TemplateTag[]
  tags?: Tag[]
  isFeatured?: boolean
  productIds?: string[]
  wdcId?: string
  attempts?: number
  gameConfigId?: string
  rakePercentage?: number
  
  toJSON?(): object
}

export interface ITemplateJSON {
  id?: string
  name?: string
  block?: Block
  schedule?: Schedule
  block_id?: string
  schedule_id?: string
  game_id?: string
  game_name?: string
  offset_mins?: number
  start_time?: string
  end_time?: string
  created_at?: string
  created_by?: string
  per_session_duration_secs?: number
  template_tags?: TemplateTag[]
  tags?: Tag[]
  is_featured?: boolean
  product_ids?: string[]
  wdc_id?: string
  attempts?: number
  game_config_id?: string
  rake_percentage?: number
  toJSON?(): object
}

export class Template implements ITemplate {
  private _json: ITemplateJSON

  constructor(m?: ITemplate) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['block'] = m.block
      this._json['schedule'] = m.schedule
      this._json['block_id'] = m.blockId
      this._json['schedule_id'] = m.scheduleId
      this._json['game_id'] = m.gameId
      this._json['game_name'] = m.gameName
      this._json['offset_mins'] = m.offsetMins
      this._json['start_time'] = m.startTime
      this._json['end_time'] = m.endTime
      this._json['created_at'] = m.createdAt
      this._json['created_by'] = m.createdBy
      this._json['per_session_duration_secs'] = m.perSessionDurationSecs
      this._json['template_tags'] = m.templateTags
      this._json['tags'] = m.tags
      this._json['is_featured'] = m.isFeatured
      this._json['product_ids'] = m.productIds
      this._json['wdc_id'] = m.wdcId
      this._json['attempts'] = m.attempts
      this._json['game_config_id'] = m.gameConfigId
      this._json['rake_percentage'] = m.rakePercentage
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // block (block)
  public get block(): Block {
    return this._json.block!
  }
  public set block(value: Block) {
    this._json.block = value
  }
  
  // schedule (schedule)
  public get schedule(): Schedule {
    return this._json.schedule!
  }
  public set schedule(value: Schedule) {
    this._json.schedule = value
  }
  
  // blockId (block_id)
  public get blockId(): string {
    return this._json.block_id!
  }
  public set blockId(value: string) {
    this._json.block_id = value
  }
  
  // scheduleId (schedule_id)
  public get scheduleId(): string {
    return this._json.schedule_id!
  }
  public set scheduleId(value: string) {
    this._json.schedule_id = value
  }
  
  // gameId (game_id)
  public get gameId(): string {
    return this._json.game_id!
  }
  public set gameId(value: string) {
    this._json.game_id = value
  }
  
  // gameName (game_name)
  public get gameName(): string {
    return this._json.game_name!
  }
  public set gameName(value: string) {
    this._json.game_name = value
  }
  
  // offsetMins (offset_mins)
  public get offsetMins(): number {
    return this._json.offset_mins!
  }
  public set offsetMins(value: number) {
    this._json.offset_mins = value
  }
  
  // startTime (start_time)
  public get startTime(): string {
    return this._json.start_time!
  }
  public set startTime(value: string) {
    this._json.start_time = value
  }
  
  // endTime (end_time)
  public get endTime(): string {
    return this._json.end_time!
  }
  public set endTime(value: string) {
    this._json.end_time = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // createdBy (created_by)
  public get createdBy(): string {
    return this._json.created_by!
  }
  public set createdBy(value: string) {
    this._json.created_by = value
  }
  
  // perSessionDurationSecs (per_session_duration_secs)
  public get perSessionDurationSecs(): number {
    return this._json.per_session_duration_secs!
  }
  public set perSessionDurationSecs(value: number) {
    this._json.per_session_duration_secs = value
  }
  
  // templateTags (template_tags)
  public get templateTags(): TemplateTag[] {
    return this._json.template_tags || []
  }
  public set templateTags(value: TemplateTag[]) {
    this._json.template_tags = value
  }
  
  // tags (tags)
  public get tags(): Tag[] {
    return this._json.tags || []
  }
  public set tags(value: Tag[]) {
    this._json.tags = value
  }
  
  // isFeatured (is_featured)
  public get isFeatured(): boolean {
    return this._json.is_featured!
  }
  public set isFeatured(value: boolean) {
    this._json.is_featured = value
  }
  
  // productIds (product_ids)
  public get productIds(): string[] {
    return this._json.product_ids || []
  }
  public set productIds(value: string[]) {
    this._json.product_ids = value
  }
  
  // wdcId (wdc_id)
  public get wdcId(): string {
    return this._json.wdc_id!
  }
  public set wdcId(value: string) {
    this._json.wdc_id = value
  }
  
  // attempts (attempts)
  public get attempts(): number {
    return this._json.attempts!
  }
  public set attempts(value: number) {
    this._json.attempts = value
  }
  
  // gameConfigId (game_config_id)
  public get gameConfigId(): string {
    return this._json.game_config_id!
  }
  public set gameConfigId(value: string) {
    this._json.game_config_id = value
  }
  
  // rakePercentage (rake_percentage)
  public get rakePercentage(): number {
    return this._json.rake_percentage!
  }
  public set rakePercentage(value: number) {
    this._json.rake_percentage = value
  }
  
  static fromJSON(m: ITemplateJSON = {}): Template {
    return new Template({
      id: m['id']!,
      name: m['name']!,
      block: Block.fromJSON(m['block']!),
      schedule: Schedule.fromJSON(m['schedule']!),
      blockId: m['block_id']!,
      scheduleId: m['schedule_id']!,
      gameId: m['game_id']!,
      gameName: m['game_name']!,
      offsetMins: m['offset_mins']!,
      startTime: m['start_time']!,
      endTime: m['end_time']!,
      createdAt: m['created_at']!,
      createdBy: m['created_by']!,
      perSessionDurationSecs: m['per_session_duration_secs']!,
      templateTags: (m['template_tags']! || []).map((v) => { return TemplateTag.fromJSON(v) }),
      tags: (m['tags']! || []).map((v) => { return Tag.fromJSON(v) }),
      isFeatured: m['is_featured']!,
      productIds: (m['product_ids']! || []).map((v) => { return String(v)}),
      wdcId: m['wdc_id']!,
      attempts: m['attempts']!,
      gameConfigId: m['game_config_id']!,
      rakePercentage: m['rake_percentage']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITemplateTag {
  targetPoint?: number
  tagIds?: string[]
  
  toJSON?(): object
}

export interface ITemplateTagJSON {
  target_point?: number
  tag_ids?: string[]
  toJSON?(): object
}

export class TemplateTag implements ITemplateTag {
  private _json: ITemplateTagJSON

  constructor(m?: ITemplateTag) {
    this._json = {}
    if (m) {
      this._json['target_point'] = m.targetPoint
      this._json['tag_ids'] = m.tagIds
    }
  }
  
  // targetPoint (target_point)
  public get targetPoint(): number {
    return this._json.target_point!
  }
  public set targetPoint(value: number) {
    this._json.target_point = value
  }
  
  // tagIds (tag_ids)
  public get tagIds(): string[] {
    return this._json.tag_ids || []
  }
  public set tagIds(value: string[]) {
    this._json.tag_ids = value
  }
  
  static fromJSON(m: ITemplateTagJSON = {}): TemplateTag {
    return new TemplateTag({
      targetPoint: m['target_point']!,
      tagIds: (m['tag_ids']! || []).map((v) => { return String(v)})
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReplaceRequest {
  parentTemplateId?: string
  childTemplate?: Template
  
  toJSON?(): object
}

export interface IReplaceRequestJSON {
  parent_template_id?: string
  child_template?: Template
  toJSON?(): object
}

export class ReplaceRequest implements IReplaceRequest {
  private _json: IReplaceRequestJSON

  constructor(m?: IReplaceRequest) {
    this._json = {}
    if (m) {
      this._json['parent_template_id'] = m.parentTemplateId
      this._json['child_template'] = m.childTemplate
    }
  }
  
  // parentTemplateId (parent_template_id)
  public get parentTemplateId(): string {
    return this._json.parent_template_id!
  }
  public set parentTemplateId(value: string) {
    this._json.parent_template_id = value
  }
  
  // childTemplate (child_template)
  public get childTemplate(): Template {
    return this._json.child_template!
  }
  public set childTemplate(value: Template) {
    this._json.child_template = value
  }
  
  static fromJSON(m: IReplaceRequestJSON = {}): ReplaceRequest {
    return new ReplaceRequest({
      parentTemplateId: m['parent_template_id']!,
      childTemplate: Template.fromJSON(m['child_template']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRoom {
  id?: string
  name?: string
  t1Timestamp?: string
  t2Timestamp?: string
  t3Timestamp?: string
  t4Timestamp?: string
  template?: Template
  isSimulated?: boolean
  
  toJSON?(): object
}

export interface IRoomJSON {
  id?: string
  name?: string
  t1_timestamp?: string
  t2_timestamp?: string
  t3_timestamp?: string
  t4_timestamp?: string
  template?: Template
  is_simulated?: boolean
  toJSON?(): object
}

export class Room implements IRoom {
  private _json: IRoomJSON

  constructor(m?: IRoom) {
    this._json = {}
    if (m) {
      this._json['id'] = m.id
      this._json['name'] = m.name
      this._json['t1_timestamp'] = m.t1Timestamp
      this._json['t2_timestamp'] = m.t2Timestamp
      this._json['t3_timestamp'] = m.t3Timestamp
      this._json['t4_timestamp'] = m.t4Timestamp
      this._json['template'] = m.template
      this._json['is_simulated'] = m.isSimulated
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // t1Timestamp (t1_timestamp)
  public get t1Timestamp(): string {
    return this._json.t1_timestamp!
  }
  public set t1Timestamp(value: string) {
    this._json.t1_timestamp = value
  }
  
  // t2Timestamp (t2_timestamp)
  public get t2Timestamp(): string {
    return this._json.t2_timestamp!
  }
  public set t2Timestamp(value: string) {
    this._json.t2_timestamp = value
  }
  
  // t3Timestamp (t3_timestamp)
  public get t3Timestamp(): string {
    return this._json.t3_timestamp!
  }
  public set t3Timestamp(value: string) {
    this._json.t3_timestamp = value
  }
  
  // t4Timestamp (t4_timestamp)
  public get t4Timestamp(): string {
    return this._json.t4_timestamp!
  }
  public set t4Timestamp(value: string) {
    this._json.t4_timestamp = value
  }
  
  // template (template)
  public get template(): Template {
    return this._json.template!
  }
  public set template(value: Template) {
    this._json.template = value
  }
  
  // isSimulated (is_simulated)
  public get isSimulated(): boolean {
    return this._json.is_simulated!
  }
  public set isSimulated(value: boolean) {
    this._json.is_simulated = value
  }
  
  static fromJSON(m: IRoomJSON = {}): Room {
    return new Room({
      id: m['id']!,
      name: m['name']!,
      t1Timestamp: m['t1_timestamp']!,
      t2Timestamp: m['t2_timestamp']!,
      t3Timestamp: m['t3_timestamp']!,
      t4Timestamp: m['t4_timestamp']!,
      template: Template.fromJSON(m['template']!),
      isSimulated: m['is_simulated']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IInterval {
  start?: string
  end?: string
  
  toJSON?(): object
}

export interface IIntervalJSON {
  start?: string
  end?: string
  toJSON?(): object
}

export class Interval implements IInterval {
  private _json: IIntervalJSON

  constructor(m?: IInterval) {
    this._json = {}
    if (m) {
      this._json['start'] = m.start
      this._json['end'] = m.end
    }
  }
  
  // start (start)
  public get start(): string {
    return this._json.start!
  }
  public set start(value: string) {
    this._json.start = value
  }
  
  // end (end)
  public get end(): string {
    return this._json.end!
  }
  public set end(value: string) {
    this._json.end = value
  }
  
  static fromJSON(m: IIntervalJSON = {}): Interval {
    return new Interval({
      start: m['start']!,
      end: m['end']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITemplateValidations {
  name?: string
  failed?: boolean
  failedIntervals?: Interval[]
  
  toJSON?(): object
}

export interface ITemplateValidationsJSON {
  name?: string
  failed?: boolean
  failed_intervals?: Interval[]
  toJSON?(): object
}

export class TemplateValidations implements ITemplateValidations {
  private _json: ITemplateValidationsJSON

  constructor(m?: ITemplateValidations) {
    this._json = {}
    if (m) {
      this._json['name'] = m.name
      this._json['failed'] = m.failed
      this._json['failed_intervals'] = m.failedIntervals
    }
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // failed (failed)
  public get failed(): boolean {
    return this._json.failed!
  }
  public set failed(value: boolean) {
    this._json.failed = value
  }
  
  // failedIntervals (failed_intervals)
  public get failedIntervals(): Interval[] {
    return this._json.failed_intervals || []
  }
  public set failedIntervals(value: Interval[]) {
    this._json.failed_intervals = value
  }
  
  static fromJSON(m: ITemplateValidationsJSON = {}): TemplateValidations {
    return new TemplateValidations({
      name: m['name']!,
      failed: m['failed']!,
      failedIntervals: (m['failed_intervals']! || []).map((v) => { return Interval.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISimulationResult {
  rooms?: Room[]
  validations?: TemplateValidations[]
  
  toJSON?(): object
}

export interface ISimulationResultJSON {
  rooms?: Room[]
  validations?: TemplateValidations[]
  toJSON?(): object
}

export class SimulationResult implements ISimulationResult {
  private _json: ISimulationResultJSON

  constructor(m?: ISimulationResult) {
    this._json = {}
    if (m) {
      this._json['rooms'] = m.rooms
      this._json['validations'] = m.validations
    }
  }
  
  // rooms (rooms)
  public get rooms(): Room[] {
    return this._json.rooms || []
  }
  public set rooms(value: Room[]) {
    this._json.rooms = value
  }
  
  // validations (validations)
  public get validations(): TemplateValidations[] {
    return this._json.validations || []
  }
  public set validations(value: TemplateValidations[]) {
    this._json.validations = value
  }
  
  static fromJSON(m: ISimulationResultJSON = {}): SimulationResult {
    return new SimulationResult({
      rooms: (m['rooms']! || []).map((v) => { return Room.fromJSON(v) }),
      validations: (m['validations']! || []).map((v) => { return TemplateValidations.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IArchiveTemplateRequest {
  templateId?: string
  
  toJSON?(): object
}

export interface IArchiveTemplateRequestJSON {
  template_id?: string
  toJSON?(): object
}

export class ArchiveTemplateRequest implements IArchiveTemplateRequest {
  private _json: IArchiveTemplateRequestJSON

  constructor(m?: IArchiveTemplateRequest) {
    this._json = {}
    if (m) {
      this._json['template_id'] = m.templateId
    }
  }
  
  // templateId (template_id)
  public get templateId(): string {
    return this._json.template_id!
  }
  public set templateId(value: string) {
    this._json.template_id = value
  }
  
  static fromJSON(m: IArchiveTemplateRequestJSON = {}): ArchiveTemplateRequest {
    return new ArchiveTemplateRequest({
      templateId: m['template_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPauseTemplateRequest {
  templateId?: string
  startTime?: string
  endTime?: string
  
  toJSON?(): object
}

export interface IPauseTemplateRequestJSON {
  template_id?: string
  start_time?: string
  end_time?: string
  toJSON?(): object
}

export class PauseTemplateRequest implements IPauseTemplateRequest {
  private _json: IPauseTemplateRequestJSON

  constructor(m?: IPauseTemplateRequest) {
    this._json = {}
    if (m) {
      this._json['template_id'] = m.templateId
      this._json['start_time'] = m.startTime
      this._json['end_time'] = m.endTime
    }
  }
  
  // templateId (template_id)
  public get templateId(): string {
    return this._json.template_id!
  }
  public set templateId(value: string) {
    this._json.template_id = value
  }
  
  // startTime (start_time)
  public get startTime(): string {
    return this._json.start_time!
  }
  public set startTime(value: string) {
    this._json.start_time = value
  }
  
  // endTime (end_time)
  public get endTime(): string {
    return this._json.end_time!
  }
  public set endTime(value: string) {
    this._json.end_time = value
  }
  
  static fromJSON(m: IPauseTemplateRequestJSON = {}): PauseTemplateRequest {
    return new PauseTemplateRequest({
      templateId: m['template_id']!,
      startTime: m['start_time']!,
      endTime: m['end_time']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IResumeTemplateRequest {
  templateId?: string
  
  toJSON?(): object
}

export interface IResumeTemplateRequestJSON {
  template_id?: string
  toJSON?(): object
}

export class ResumeTemplateRequest implements IResumeTemplateRequest {
  private _json: IResumeTemplateRequestJSON

  constructor(m?: IResumeTemplateRequest) {
    this._json = {}
    if (m) {
      this._json['template_id'] = m.templateId
    }
  }
  
  // templateId (template_id)
  public get templateId(): string {
    return this._json.template_id!
  }
  public set templateId(value: string) {
    this._json.template_id = value
  }
  
  static fromJSON(m: IResumeTemplateRequestJSON = {}): ResumeTemplateRequest {
    return new ResumeTemplateRequest({
      templateId: m['template_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICancelTemplatePauseIntervalRequest {
  templateId?: string
  templatePauseIntervalId?: string
  
  toJSON?(): object
}

export interface ICancelTemplatePauseIntervalRequestJSON {
  template_id?: string
  template_pause_interval_id?: string
  toJSON?(): object
}

export class CancelTemplatePauseIntervalRequest implements ICancelTemplatePauseIntervalRequest {
  private _json: ICancelTemplatePauseIntervalRequestJSON

  constructor(m?: ICancelTemplatePauseIntervalRequest) {
    this._json = {}
    if (m) {
      this._json['template_id'] = m.templateId
      this._json['template_pause_interval_id'] = m.templatePauseIntervalId
    }
  }
  
  // templateId (template_id)
  public get templateId(): string {
    return this._json.template_id!
  }
  public set templateId(value: string) {
    this._json.template_id = value
  }
  
  // templatePauseIntervalId (template_pause_interval_id)
  public get templatePauseIntervalId(): string {
    return this._json.template_pause_interval_id!
  }
  public set templatePauseIntervalId(value: string) {
    this._json.template_pause_interval_id = value
  }
  
  static fromJSON(m: ICancelTemplatePauseIntervalRequestJSON = {}): CancelTemplatePauseIntervalRequest {
    return new CancelTemplatePauseIntervalRequest({
      templateId: m['template_id']!,
      templatePauseIntervalId: m['template_pause_interval_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRoomStateSwitchResponse {
  results?: Results
  
  toJSON?(): object
}

export interface IRoomStateSwitchResponseJSON {
  results?: Results
  toJSON?(): object
}

export class RoomStateSwitchResponse implements IRoomStateSwitchResponse {
  private _json: IRoomStateSwitchResponseJSON

  constructor(m?: IRoomStateSwitchResponse) {
    this._json = {}
    if (m) {
      this._json['results'] = m.results
    }
  }
  
  // results (results)
  public get results(): Results {
    return this._json.results!
  }
  public set results(value: Results) {
    this._json.results = value
  }
  
  static fromJSON(m: IRoomStateSwitchResponseJSON = {}): RoomStateSwitchResponse {
    return new RoomStateSwitchResponse({
      results: Results.fromJSON(m['results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDraftRoomCreationResponse {
  results?: Results
  
  toJSON?(): object
}

export interface IDraftRoomCreationResponseJSON {
  results?: Results
  toJSON?(): object
}

export class DraftRoomCreationResponse implements IDraftRoomCreationResponse {
  private _json: IDraftRoomCreationResponseJSON

  constructor(m?: IDraftRoomCreationResponse) {
    this._json = {}
    if (m) {
      this._json['results'] = m.results
    }
  }
  
  // results (results)
  public get results(): Results {
    return this._json.results!
  }
  public set results(value: Results) {
    this._json.results = value
  }
  
  static fromJSON(m: IDraftRoomCreationResponseJSON = {}): DraftRoomCreationResponse {
    return new DraftRoomCreationResponse({
      results: Results.fromJSON(m['results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITemplatePausePlayResponse {
  results?: Results
  
  toJSON?(): object
}

export interface ITemplatePausePlayResponseJSON {
  results?: Results
  toJSON?(): object
}

export class TemplatePausePlayResponse implements ITemplatePausePlayResponse {
  private _json: ITemplatePausePlayResponseJSON

  constructor(m?: ITemplatePausePlayResponse) {
    this._json = {}
    if (m) {
      this._json['results'] = m.results
    }
  }
  
  // results (results)
  public get results(): Results {
    return this._json.results!
  }
  public set results(value: Results) {
    this._json.results = value
  }
  
  static fromJSON(m: ITemplatePausePlayResponseJSON = {}): TemplatePausePlayResponse {
    return new TemplatePausePlayResponse({
      results: Results.fromJSON(m['results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITemplateStateSwitchResponse {
  roomTemplateResults?: Results
  tourTemplateResults?: Results
  
  toJSON?(): object
}

export interface ITemplateStateSwitchResponseJSON {
  room_template_results?: Results
  tour_template_results?: Results
  toJSON?(): object
}

export class TemplateStateSwitchResponse implements ITemplateStateSwitchResponse {
  private _json: ITemplateStateSwitchResponseJSON

  constructor(m?: ITemplateStateSwitchResponse) {
    this._json = {}
    if (m) {
      this._json['room_template_results'] = m.roomTemplateResults
      this._json['tour_template_results'] = m.tourTemplateResults
    }
  }
  
  // roomTemplateResults (room_template_results)
  public get roomTemplateResults(): Results {
    return this._json.room_template_results!
  }
  public set roomTemplateResults(value: Results) {
    this._json.room_template_results = value
  }
  
  // tourTemplateResults (tour_template_results)
  public get tourTemplateResults(): Results {
    return this._json.tour_template_results!
  }
  public set tourTemplateResults(value: Results) {
    this._json.tour_template_results = value
  }
  
  static fromJSON(m: ITemplateStateSwitchResponseJSON = {}): TemplateStateSwitchResponse {
    return new TemplateStateSwitchResponse({
      roomTemplateResults: Results.fromJSON(m['room_template_results']!),
      tourTemplateResults: Results.fromJSON(m['tour_template_results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITournamentStateSwitchResponse {
  results?: Results
  
  toJSON?(): object
}

export interface ITournamentStateSwitchResponseJSON {
  results?: Results
  toJSON?(): object
}

export class TournamentStateSwitchResponse implements ITournamentStateSwitchResponse {
  private _json: ITournamentStateSwitchResponseJSON

  constructor(m?: ITournamentStateSwitchResponse) {
    this._json = {}
    if (m) {
      this._json['results'] = m.results
    }
  }
  
  // results (results)
  public get results(): Results {
    return this._json.results!
  }
  public set results(value: Results) {
    this._json.results = value
  }
  
  static fromJSON(m: ITournamentStateSwitchResponseJSON = {}): TournamentStateSwitchResponse {
    return new TournamentStateSwitchResponse({
      results: Results.fromJSON(m['results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDraftTournamentCreationResponse {
  results?: Results
  
  toJSON?(): object
}

export interface IDraftTournamentCreationResponseJSON {
  results?: Results
  toJSON?(): object
}

export class DraftTournamentCreationResponse implements IDraftTournamentCreationResponse {
  private _json: IDraftTournamentCreationResponseJSON

  constructor(m?: IDraftTournamentCreationResponse) {
    this._json = {}
    if (m) {
      this._json['results'] = m.results
    }
  }
  
  // results (results)
  public get results(): Results {
    return this._json.results!
  }
  public set results(value: Results) {
    this._json.results = value
  }
  
  static fromJSON(m: IDraftTournamentCreationResponseJSON = {}): DraftTournamentCreationResponse {
    return new DraftTournamentCreationResponse({
      results: Results.fromJSON(m['results']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IResults {
  total?: number
  failed?: number
  passed?: number
  
  toJSON?(): object
}

export interface IResultsJSON {
  total?: number
  failed?: number
  passed?: number
  toJSON?(): object
}

export class Results implements IResults {
  private _json: IResultsJSON

  constructor(m?: IResults) {
    this._json = {}
    if (m) {
      this._json['total'] = m.total
      this._json['failed'] = m.failed
      this._json['passed'] = m.passed
    }
  }
  
  // total (total)
  public get total(): number {
    return this._json.total!
  }
  public set total(value: number) {
    this._json.total = value
  }
  
  // failed (failed)
  public get failed(): number {
    return this._json.failed!
  }
  public set failed(value: number) {
    this._json.failed = value
  }
  
  // passed (passed)
  public get passed(): number {
    return this._json.passed!
  }
  public set passed(value: number) {
    this._json.passed = value
  }
  
  static fromJSON(m: IResultsJSON = {}): Results {
    return new Results({
      total: m['total']!,
      failed: m['failed']!,
      passed: m['passed']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITournamentTemplate {
  template?: Template
  type?: TournamentType
  mMConfig?: MMConfig
  minPrizeWinningScore?: number
  
  toJSON?(): object
}

export interface ITournamentTemplateJSON {
  template?: Template
  type?: TournamentType
  m_m_config?: MMConfig
  min_prize_winning_score?: number
  toJSON?(): object
}

export class TournamentTemplate implements ITournamentTemplate {
  private _json: ITournamentTemplateJSON

  constructor(m?: ITournamentTemplate) {
    this._json = {}
    if (m) {
      this._json['template'] = m.template
      this._json['type'] = m.type
      this._json['m_m_config'] = m.mMConfig
      this._json['min_prize_winning_score'] = m.minPrizeWinningScore
    }
  }
  
  // template (template)
  public get template(): Template {
    return this._json.template!
  }
  public set template(value: Template) {
    this._json.template = value
  }
  
  // type (type)
  public get type(): TournamentType {
    return (<any>TournamentType)[this._json.type!]
  }
  public set type(value: TournamentType) {
    this._json.type = value
  }
  
  // mMConfig (m_m_config)
  public get mMConfig(): MMConfig {
    return this._json.m_m_config!
  }
  public set mMConfig(value: MMConfig) {
    this._json.m_m_config = value
  }
  
  // minPrizeWinningScore (min_prize_winning_score)
  public get minPrizeWinningScore(): number {
    return this._json.min_prize_winning_score!
  }
  public set minPrizeWinningScore(value: number) {
    this._json.min_prize_winning_score = value
  }
  
  static fromJSON(m: ITournamentTemplateJSON = {}): TournamentTemplate {
    return new TournamentTemplate({
      template: Template.fromJSON(m['template']!),
      type: (<any>TournamentType)[m['type']!]!,
      mMConfig: MMConfig.fromJSON(m['m_m_config']!),
      minPrizeWinningScore: m['min_prize_winning_score']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMMConfig {
  minCount?: number
  maxCount?: number
  waitDurationSecs?: number
  
  toJSON?(): object
}

export interface IMMConfigJSON {
  min_count?: number
  max_count?: number
  wait_duration_secs?: number
  toJSON?(): object
}

export class MMConfig implements IMMConfig {
  private _json: IMMConfigJSON

  constructor(m?: IMMConfig) {
    this._json = {}
    if (m) {
      this._json['min_count'] = m.minCount
      this._json['max_count'] = m.maxCount
      this._json['wait_duration_secs'] = m.waitDurationSecs
    }
  }
  
  // minCount (min_count)
  public get minCount(): number {
    return this._json.min_count!
  }
  public set minCount(value: number) {
    this._json.min_count = value
  }
  
  // maxCount (max_count)
  public get maxCount(): number {
    return this._json.max_count!
  }
  public set maxCount(value: number) {
    this._json.max_count = value
  }
  
  // waitDurationSecs (wait_duration_secs)
  public get waitDurationSecs(): number {
    return this._json.wait_duration_secs!
  }
  public set waitDurationSecs(value: number) {
    this._json.wait_duration_secs = value
  }
  
  static fromJSON(m: IMMConfigJSON = {}): MMConfig {
    return new MMConfig({
      minCount: m['min_count']!,
      maxCount: m['max_count']!,
      waitDurationSecs: m['wait_duration_secs']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IArchiveTournamentTemplateRequest {
  tournamentTemplateId?: string
  
  toJSON?(): object
}

export interface IArchiveTournamentTemplateRequestJSON {
  tournament_template_id?: string
  toJSON?(): object
}

export class ArchiveTournamentTemplateRequest implements IArchiveTournamentTemplateRequest {
  private _json: IArchiveTournamentTemplateRequestJSON

  constructor(m?: IArchiveTournamentTemplateRequest) {
    this._json = {}
    if (m) {
      this._json['tournament_template_id'] = m.tournamentTemplateId
    }
  }
  
  // tournamentTemplateId (tournament_template_id)
  public get tournamentTemplateId(): string {
    return this._json.tournament_template_id!
  }
  public set tournamentTemplateId(value: string) {
    this._json.tournament_template_id = value
  }
  
  static fromJSON(m: IArchiveTournamentTemplateRequestJSON = {}): ArchiveTournamentTemplateRequest {
    return new ArchiveTournamentTemplateRequest({
      tournamentTemplateId: m['tournament_template_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycDocumentDetailsRequest {
  orderId?: string
  
  toJSON?(): object
}

export interface IKycDocumentDetailsRequestJSON {
  order_id?: string
  toJSON?(): object
}

export class KycDocumentDetailsRequest implements IKycDocumentDetailsRequest {
  private _json: IKycDocumentDetailsRequestJSON

  constructor(m?: IKycDocumentDetailsRequest) {
    this._json = {}
    if (m) {
      this._json['order_id'] = m.orderId
    }
  }
  
  // orderId (order_id)
  public get orderId(): string {
    return this._json.order_id!
  }
  public set orderId(value: string) {
    this._json.order_id = value
  }
  
  static fromJSON(m: IKycDocumentDetailsRequestJSON = {}): KycDocumentDetailsRequest {
    return new KycDocumentDetailsRequest({
      orderId: m['order_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycPanDetailsRequest {
  playerId?: string
  
  toJSON?(): object
}

export interface IKycPanDetailsRequestJSON {
  player_id?: string
  toJSON?(): object
}

export class KycPanDetailsRequest implements IKycPanDetailsRequest {
  private _json: IKycPanDetailsRequestJSON

  constructor(m?: IKycPanDetailsRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  static fromJSON(m: IKycPanDetailsRequestJSON = {}): KycPanDetailsRequest {
    return new KycPanDetailsRequest({
      playerId: m['player_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycFileUrls {
  front?: string
  back?: string
  
  toJSON?(): object
}

export interface IKycFileUrlsJSON {
  front?: string
  back?: string
  toJSON?(): object
}

export class KycFileUrls implements IKycFileUrls {
  private _json: IKycFileUrlsJSON

  constructor(m?: IKycFileUrls) {
    this._json = {}
    if (m) {
      this._json['front'] = m.front
      this._json['back'] = m.back
    }
  }
  
  // front (front)
  public get front(): string {
    return this._json.front!
  }
  public set front(value: string) {
    this._json.front = value
  }
  
  // back (back)
  public get back(): string {
    return this._json.back!
  }
  public set back(value: string) {
    this._json.back = value
  }
  
  static fromJSON(m: IKycFileUrlsJSON = {}): KycFileUrls {
    return new KycFileUrls({
      front: m['front']!,
      back: m['back']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IkycCheckDetails {
  checkName?: string
  status?: KycStatus
  failingReason?: string
  
  toJSON?(): object
}

export interface IkycCheckDetailsJSON {
  check_name?: string
  status?: KycStatus
  failing_reason?: string
  toJSON?(): object
}

export class kycCheckDetails implements IkycCheckDetails {
  private _json: IkycCheckDetailsJSON

  constructor(m?: IkycCheckDetails) {
    this._json = {}
    if (m) {
      this._json['check_name'] = m.checkName
      this._json['status'] = m.status
      this._json['failing_reason'] = m.failingReason
    }
  }
  
  // checkName (check_name)
  public get checkName(): string {
    return this._json.check_name!
  }
  public set checkName(value: string) {
    this._json.check_name = value
  }
  
  // status (status)
  public get status(): KycStatus {
    return (<any>KycStatus)[this._json.status!]
  }
  public set status(value: KycStatus) {
    this._json.status = value
  }
  
  // failingReason (failing_reason)
  public get failingReason(): string {
    return this._json.failing_reason!
  }
  public set failingReason(value: string) {
    this._json.failing_reason = value
  }
  
  static fromJSON(m: IkycCheckDetailsJSON = {}): kycCheckDetails {
    return new kycCheckDetails({
      checkName: m['check_name']!,
      status: (<any>KycStatus)[m['status']!]!,
      failingReason: m['failing_reason']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycCardDetails {
  nameOnCard?: string
  cardNumber?: string
  dob?: string
  
  toJSON?(): object
}

export interface IKycCardDetailsJSON {
  name_on_card?: string
  card_number?: string
  dob?: string
  toJSON?(): object
}

export class KycCardDetails implements IKycCardDetails {
  private _json: IKycCardDetailsJSON

  constructor(m?: IKycCardDetails) {
    this._json = {}
    if (m) {
      this._json['name_on_card'] = m.nameOnCard
      this._json['card_number'] = m.cardNumber
      this._json['dob'] = m.dob
    }
  }
  
  // nameOnCard (name_on_card)
  public get nameOnCard(): string {
    return this._json.name_on_card!
  }
  public set nameOnCard(value: string) {
    this._json.name_on_card = value
  }
  
  // cardNumber (card_number)
  public get cardNumber(): string {
    return this._json.card_number!
  }
  public set cardNumber(value: string) {
    this._json.card_number = value
  }
  
  // dob (dob)
  public get dob(): string {
    return this._json.dob!
  }
  public set dob(value: string) {
    this._json.dob = value
  }
  
  static fromJSON(m: IKycCardDetailsJSON = {}): KycCardDetails {
    return new KycCardDetails({
      nameOnCard: m['name_on_card']!,
      cardNumber: m['card_number']!,
      dob: m['dob']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycDocTypeUploadCountInfo {
  userUploadCount?: number
  wildUploadsGiven?: number
  defaultUploadsGiven?: number
  
  toJSON?(): object
}

export interface IKycDocTypeUploadCountInfoJSON {
  user_upload_count?: number
  wild_uploads_given?: number
  default_uploads_given?: number
  toJSON?(): object
}

export class KycDocTypeUploadCountInfo implements IKycDocTypeUploadCountInfo {
  private _json: IKycDocTypeUploadCountInfoJSON

  constructor(m?: IKycDocTypeUploadCountInfo) {
    this._json = {}
    if (m) {
      this._json['user_upload_count'] = m.userUploadCount
      this._json['wild_uploads_given'] = m.wildUploadsGiven
      this._json['default_uploads_given'] = m.defaultUploadsGiven
    }
  }
  
  // userUploadCount (user_upload_count)
  public get userUploadCount(): number {
    return this._json.user_upload_count!
  }
  public set userUploadCount(value: number) {
    this._json.user_upload_count = value
  }
  
  // wildUploadsGiven (wild_uploads_given)
  public get wildUploadsGiven(): number {
    return this._json.wild_uploads_given!
  }
  public set wildUploadsGiven(value: number) {
    this._json.wild_uploads_given = value
  }
  
  // defaultUploadsGiven (default_uploads_given)
  public get defaultUploadsGiven(): number {
    return this._json.default_uploads_given!
  }
  public set defaultUploadsGiven(value: number) {
    this._json.default_uploads_given = value
  }
  
  static fromJSON(m: IKycDocTypeUploadCountInfoJSON = {}): KycDocTypeUploadCountInfo {
    return new KycDocTypeUploadCountInfo({
      userUploadCount: m['user_upload_count']!,
      wildUploadsGiven: m['wild_uploads_given']!,
      defaultUploadsGiven: m['default_uploads_given']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycUploadCountInfo {
  pan?: KycDocTypeUploadCountInfo
  otherDocuments?: KycDocTypeUploadCountInfo
  orderHistory?: string[]
  
  toJSON?(): object
}

export interface IKycUploadCountInfoJSON {
  pan?: KycDocTypeUploadCountInfo
  other_documents?: KycDocTypeUploadCountInfo
  order_history?: string[]
  toJSON?(): object
}

export class KycUploadCountInfo implements IKycUploadCountInfo {
  private _json: IKycUploadCountInfoJSON

  constructor(m?: IKycUploadCountInfo) {
    this._json = {}
    if (m) {
      this._json['pan'] = m.pan
      this._json['other_documents'] = m.otherDocuments
      this._json['order_history'] = m.orderHistory
    }
  }
  
  // pan (pan)
  public get pan(): KycDocTypeUploadCountInfo {
    return this._json.pan!
  }
  public set pan(value: KycDocTypeUploadCountInfo) {
    this._json.pan = value
  }
  
  // otherDocuments (other_documents)
  public get otherDocuments(): KycDocTypeUploadCountInfo {
    return this._json.other_documents!
  }
  public set otherDocuments(value: KycDocTypeUploadCountInfo) {
    this._json.other_documents = value
  }
  
  // orderHistory (order_history)
  public get orderHistory(): string[] {
    return this._json.order_history || []
  }
  public set orderHistory(value: string[]) {
    this._json.order_history = value
  }
  
  static fromJSON(m: IKycUploadCountInfoJSON = {}): KycUploadCountInfo {
    return new KycUploadCountInfo({
      pan: KycDocTypeUploadCountInfo.fromJSON(m['pan']!),
      otherDocuments: KycDocTypeUploadCountInfo.fromJSON(m['other_documents']!),
      orderHistory: (m['order_history']! || []).map((v) => { return String(v)})
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycDocumentDetailsResponse {
  documentType?: KycDocumentType
  detailsByUser?: KycCardDetails
  detailsByOcr?: KycDocumentDetailsResponse_DetailsByOcrEntry[]
  checksDetails?: kycCheckDetails[]
  documentStatus?: KycStatus
  fileUrls?: KycFileUrls
  uploadCountInfo?: KycUploadCountInfo
  verifiedByWatson?: boolean
  
  toJSON?(): object
}

export interface IKycDocumentDetailsResponseJSON {
  document_type?: KycDocumentType
  details_by_user?: KycCardDetails
  details_by_ocr?: KycDocumentDetailsResponse_DetailsByOcrEntry[]
  checks_details?: kycCheckDetails[]
  document_status?: KycStatus
  file_urls?: KycFileUrls
  upload_count_info?: KycUploadCountInfo
  verified_by_watson?: boolean
  toJSON?(): object
}

export class KycDocumentDetailsResponse implements IKycDocumentDetailsResponse {
  private _json: IKycDocumentDetailsResponseJSON

  constructor(m?: IKycDocumentDetailsResponse) {
    this._json = {}
    if (m) {
      this._json['document_type'] = m.documentType
      this._json['details_by_user'] = m.detailsByUser
      this._json['details_by_ocr'] = m.detailsByOcr
      this._json['checks_details'] = m.checksDetails
      this._json['document_status'] = m.documentStatus
      this._json['file_urls'] = m.fileUrls
      this._json['upload_count_info'] = m.uploadCountInfo
      this._json['verified_by_watson'] = m.verifiedByWatson
    }
  }
  
  // documentType (document_type)
  public get documentType(): KycDocumentType {
    return (<any>KycDocumentType)[this._json.document_type!]
  }
  public set documentType(value: KycDocumentType) {
    this._json.document_type = value
  }
  
  // detailsByUser (details_by_user)
  public get detailsByUser(): KycCardDetails {
    return this._json.details_by_user!
  }
  public set detailsByUser(value: KycCardDetails) {
    this._json.details_by_user = value
  }
  
  // detailsByOcr (details_by_ocr)
  public get detailsByOcr(): KycDocumentDetailsResponse_DetailsByOcrEntry[] {
    return this._json.details_by_ocr || []
  }
  public set detailsByOcr(value: KycDocumentDetailsResponse_DetailsByOcrEntry[]) {
    this._json.details_by_ocr = value
  }
  
  // checksDetails (checks_details)
  public get checksDetails(): kycCheckDetails[] {
    return this._json.checks_details || []
  }
  public set checksDetails(value: kycCheckDetails[]) {
    this._json.checks_details = value
  }
  
  // documentStatus (document_status)
  public get documentStatus(): KycStatus {
    return (<any>KycStatus)[this._json.document_status!]
  }
  public set documentStatus(value: KycStatus) {
    this._json.document_status = value
  }
  
  // fileUrls (file_urls)
  public get fileUrls(): KycFileUrls {
    return this._json.file_urls!
  }
  public set fileUrls(value: KycFileUrls) {
    this._json.file_urls = value
  }
  
  // uploadCountInfo (upload_count_info)
  public get uploadCountInfo(): KycUploadCountInfo {
    return this._json.upload_count_info!
  }
  public set uploadCountInfo(value: KycUploadCountInfo) {
    this._json.upload_count_info = value
  }
  
  // verifiedByWatson (verified_by_watson)
  public get verifiedByWatson(): boolean {
    return this._json.verified_by_watson!
  }
  public set verifiedByWatson(value: boolean) {
    this._json.verified_by_watson = value
  }
  
  static fromJSON(m: IKycDocumentDetailsResponseJSON = {}): KycDocumentDetailsResponse {
    return new KycDocumentDetailsResponse({
      documentType: (<any>KycDocumentType)[m['document_type']!]!,
      detailsByUser: KycCardDetails.fromJSON(m['details_by_user']!),
      detailsByOcr: (m['details_by_ocr']! || []).map((v) => { return KycDocumentDetailsResponse_DetailsByOcrEntry.fromJSON(v) }),
      checksDetails: (m['checks_details']! || []).map((v) => { return kycCheckDetails.fromJSON(v) }),
      documentStatus: (<any>KycStatus)[m['document_status']!]!,
      fileUrls: KycFileUrls.fromJSON(m['file_urls']!),
      uploadCountInfo: KycUploadCountInfo.fromJSON(m['upload_count_info']!),
      verifiedByWatson: m['verified_by_watson']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycUpdateDocumentStatusRequest {
  orderId?: string
  isVerified?: boolean
  verificationComment?: string
  userStatusText?: UserStatusText
  cardDetails?: KycCardDetails
  
  toJSON?(): object
}

export interface IKycUpdateDocumentStatusRequestJSON {
  order_id?: string
  is_verified?: boolean
  verification_comment?: string
  user_status_text?: UserStatusText
  card_details?: KycCardDetails
  toJSON?(): object
}

export class KycUpdateDocumentStatusRequest implements IKycUpdateDocumentStatusRequest {
  private _json: IKycUpdateDocumentStatusRequestJSON

  constructor(m?: IKycUpdateDocumentStatusRequest) {
    this._json = {}
    if (m) {
      this._json['order_id'] = m.orderId
      this._json['is_verified'] = m.isVerified
      this._json['verification_comment'] = m.verificationComment
      this._json['user_status_text'] = m.userStatusText
      this._json['card_details'] = m.cardDetails
    }
  }
  
  // orderId (order_id)
  public get orderId(): string {
    return this._json.order_id!
  }
  public set orderId(value: string) {
    this._json.order_id = value
  }
  
  // isVerified (is_verified)
  public get isVerified(): boolean {
    return this._json.is_verified!
  }
  public set isVerified(value: boolean) {
    this._json.is_verified = value
  }
  
  // verificationComment (verification_comment)
  public get verificationComment(): string {
    return this._json.verification_comment!
  }
  public set verificationComment(value: string) {
    this._json.verification_comment = value
  }
  
  // userStatusText (user_status_text)
  public get userStatusText(): UserStatusText {
    return (<any>UserStatusText)[this._json.user_status_text!]
  }
  public set userStatusText(value: UserStatusText) {
    this._json.user_status_text = value
  }
  
  // cardDetails (card_details)
  public get cardDetails(): KycCardDetails {
    return this._json.card_details!
  }
  public set cardDetails(value: KycCardDetails) {
    this._json.card_details = value
  }
  
  static fromJSON(m: IKycUpdateDocumentStatusRequestJSON = {}): KycUpdateDocumentStatusRequest {
    return new KycUpdateDocumentStatusRequest({
      orderId: m['order_id']!,
      isVerified: m['is_verified']!,
      verificationComment: m['verification_comment']!,
      userStatusText: (<any>UserStatusText)[m['user_status_text']!]!,
      cardDetails: KycCardDetails.fromJSON(m['card_details']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycProvideWildUploadsRequest {
  playerId?: string
  pan?: number
  otherDocument?: number
  
  toJSON?(): object
}

export interface IKycProvideWildUploadsRequestJSON {
  player_id?: string
  pan?: number
  other_document?: number
  toJSON?(): object
}

export class KycProvideWildUploadsRequest implements IKycProvideWildUploadsRequest {
  private _json: IKycProvideWildUploadsRequestJSON

  constructor(m?: IKycProvideWildUploadsRequest) {
    this._json = {}
    if (m) {
      this._json['player_id'] = m.playerId
      this._json['pan'] = m.pan
      this._json['other_document'] = m.otherDocument
    }
  }
  
  // playerId (player_id)
  public get playerId(): string {
    return this._json.player_id!
  }
  public set playerId(value: string) {
    this._json.player_id = value
  }
  
  // pan (pan)
  public get pan(): number {
    return this._json.pan!
  }
  public set pan(value: number) {
    this._json.pan = value
  }
  
  // otherDocument (other_document)
  public get otherDocument(): number {
    return this._json.other_document!
  }
  public set otherDocument(value: number) {
    this._json.other_document = value
  }
  
  static fromJSON(m: IKycProvideWildUploadsRequestJSON = {}): KycProvideWildUploadsRequest {
    return new KycProvideWildUploadsRequest({
      playerId: m['player_id']!,
      pan: m['pan']!,
      otherDocument: m['other_document']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IKycProvideWildUploadsResponse {
  uploadCountInfo?: KycUploadCountInfo
  
  toJSON?(): object
}

export interface IKycProvideWildUploadsResponseJSON {
  upload_count_info?: KycUploadCountInfo
  toJSON?(): object
}

export class KycProvideWildUploadsResponse implements IKycProvideWildUploadsResponse {
  private _json: IKycProvideWildUploadsResponseJSON

  constructor(m?: IKycProvideWildUploadsResponse) {
    this._json = {}
    if (m) {
      this._json['upload_count_info'] = m.uploadCountInfo
    }
  }
  
  // uploadCountInfo (upload_count_info)
  public get uploadCountInfo(): KycUploadCountInfo {
    return this._json.upload_count_info!
  }
  public set uploadCountInfo(value: KycUploadCountInfo) {
    this._json.upload_count_info = value
  }
  
  static fromJSON(m: IKycProvideWildUploadsResponseJSON = {}): KycProvideWildUploadsResponse {
    return new KycProvideWildUploadsResponse({
      uploadCountInfo: KycUploadCountInfo.fromJSON(m['upload_count_info']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IWinningsDistributionRequest {
  wdfFunction?: WinningsDistributionFunction
  percentageWinners?: string
  playersCount?: number
  entryFeeCollected?: number
  entryFeeCurrency?: string
  rakePercentage?: number
  
  toJSON?(): object
}

export interface IWinningsDistributionRequestJSON {
  wdf_function?: WinningsDistributionFunction
  percentage_winners?: string
  players_count?: number
  entry_fee_collected?: number
  entry_fee_currency?: string
  rake_percentage?: number
  toJSON?(): object
}

export class WinningsDistributionRequest implements IWinningsDistributionRequest {
  private _json: IWinningsDistributionRequestJSON

  constructor(m?: IWinningsDistributionRequest) {
    this._json = {}
    if (m) {
      this._json['wdf_function'] = m.wdfFunction
      this._json['percentage_winners'] = m.percentageWinners
      this._json['players_count'] = m.playersCount
      this._json['entry_fee_collected'] = m.entryFeeCollected
      this._json['entry_fee_currency'] = m.entryFeeCurrency
      this._json['rake_percentage'] = m.rakePercentage
    }
  }
  
  // wdfFunction (wdf_function)
  public get wdfFunction(): WinningsDistributionFunction {
    return (<any>WinningsDistributionFunction)[this._json.wdf_function!]
  }
  public set wdfFunction(value: WinningsDistributionFunction) {
    this._json.wdf_function = value
  }
  
  // percentageWinners (percentage_winners)
  public get percentageWinners(): string {
    return this._json.percentage_winners!
  }
  public set percentageWinners(value: string) {
    this._json.percentage_winners = value
  }
  
  // playersCount (players_count)
  public get playersCount(): number {
    return this._json.players_count!
  }
  public set playersCount(value: number) {
    this._json.players_count = value
  }
  
  // entryFeeCollected (entry_fee_collected)
  public get entryFeeCollected(): number {
    return this._json.entry_fee_collected!
  }
  public set entryFeeCollected(value: number) {
    this._json.entry_fee_collected = value
  }
  
  // entryFeeCurrency (entry_fee_currency)
  public get entryFeeCurrency(): string {
    return this._json.entry_fee_currency!
  }
  public set entryFeeCurrency(value: string) {
    this._json.entry_fee_currency = value
  }
  
  // rakePercentage (rake_percentage)
  public get rakePercentage(): number {
    return this._json.rake_percentage!
  }
  public set rakePercentage(value: number) {
    this._json.rake_percentage = value
  }
  
  static fromJSON(m: IWinningsDistributionRequestJSON = {}): WinningsDistributionRequest {
    return new WinningsDistributionRequest({
      wdfFunction: (<any>WinningsDistributionFunction)[m['wdf_function']!]!,
      percentageWinners: m['percentage_winners']!,
      playersCount: m['players_count']!,
      entryFeeCollected: m['entry_fee_collected']!,
      entryFeeCurrency: m['entry_fee_currency']!,
      rakePercentage: m['rake_percentage']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IWinningsDistributionList {
  winningsDistribution?: WinningsDistribution[]
  
  toJSON?(): object
}

export interface IWinningsDistributionListJSON {
  winnings_distribution?: WinningsDistribution[]
  toJSON?(): object
}

export class WinningsDistributionList implements IWinningsDistributionList {
  private _json: IWinningsDistributionListJSON

  constructor(m?: IWinningsDistributionList) {
    this._json = {}
    if (m) {
      this._json['winnings_distribution'] = m.winningsDistribution
    }
  }
  
  // winningsDistribution (winnings_distribution)
  public get winningsDistribution(): WinningsDistribution[] {
    return this._json.winnings_distribution || []
  }
  public set winningsDistribution(value: WinningsDistribution[]) {
    this._json.winnings_distribution = value
  }
  
  static fromJSON(m: IWinningsDistributionListJSON = {}): WinningsDistributionList {
    return new WinningsDistributionList({
      winningsDistribution: (m['winnings_distribution']! || []).map((v) => { return WinningsDistribution.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IWinningsDistribution {
  startRank?: number
  endRank?: number
  winningsPercentage?: number
  winningsAmount?: number
  winningsCurrency?: string
  
  toJSON?(): object
}

export interface IWinningsDistributionJSON {
  start_rank?: number
  end_rank?: number
  winnings_percentage?: number
  winnings_amount?: number
  winnings_currency?: string
  toJSON?(): object
}

export class WinningsDistribution implements IWinningsDistribution {
  private _json: IWinningsDistributionJSON

  constructor(m?: IWinningsDistribution) {
    this._json = {}
    if (m) {
      this._json['start_rank'] = m.startRank
      this._json['end_rank'] = m.endRank
      this._json['winnings_percentage'] = m.winningsPercentage
      this._json['winnings_amount'] = m.winningsAmount
      this._json['winnings_currency'] = m.winningsCurrency
    }
  }
  
  // startRank (start_rank)
  public get startRank(): number {
    return this._json.start_rank!
  }
  public set startRank(value: number) {
    this._json.start_rank = value
  }
  
  // endRank (end_rank)
  public get endRank(): number {
    return this._json.end_rank!
  }
  public set endRank(value: number) {
    this._json.end_rank = value
  }
  
  // winningsPercentage (winnings_percentage)
  public get winningsPercentage(): number {
    return this._json.winnings_percentage!
  }
  public set winningsPercentage(value: number) {
    this._json.winnings_percentage = value
  }
  
  // winningsAmount (winnings_amount)
  public get winningsAmount(): number {
    return this._json.winnings_amount!
  }
  public set winningsAmount(value: number) {
    this._json.winnings_amount = value
  }
  
  // winningsCurrency (winnings_currency)
  public get winningsCurrency(): string {
    return this._json.winnings_currency!
  }
  public set winningsCurrency(value: string) {
    this._json.winnings_currency = value
  }
  
  static fromJSON(m: IWinningsDistributionJSON = {}): WinningsDistribution {
    return new WinningsDistribution({
      startRank: m['start_rank']!,
      endRank: m['end_rank']!,
      winningsPercentage: m['winnings_percentage']!,
      winningsAmount: m['winnings_amount']!,
      winningsCurrency: m['winnings_currency']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}



// Services
export interface IBlockService {
  createBlock: (data: Block, headers?: object) => Promise<Block>
  archiveBlock: (data: Block, headers?: object) => Promise<protobuf_Empty>
  editBlock: (data: Block, headers?: object) => Promise<Block>
}

export class BlockService implements IBlockService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.BlockService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createBlock(params: Block, headers: object = {}): Promise<Block> {
    return this.fetch(
      this.url('CreateBlock'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Block.fromJSON(m)})
    })
  }
  
  public archiveBlock(params: Block, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ArchiveBlock'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
  public editBlock(params: Block, headers: object = {}): Promise<Block> {
    return this.fetch(
      this.url('EditBlock'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Block.fromJSON(m)})
    })
  }
  
}export interface IManageUser {
  disable: (data: EnableDisableUserRequest, headers?: object) => Promise<EnableDisableUserResponse>
  enable: (data: EnableDisableUserRequest, headers?: object) => Promise<EnableDisableUserResponse>
}

export class ManageUser implements IManageUser {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.ManageUser/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public disable(params: EnableDisableUserRequest, headers: object = {}): Promise<EnableDisableUserResponse> {
    return this.fetch(
      this.url('Disable'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return EnableDisableUserResponse.fromJSON(m)})
    })
  }
  
  public enable(params: EnableDisableUserRequest, headers: object = {}): Promise<EnableDisableUserResponse> {
    return this.fetch(
      this.url('Enable'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return EnableDisableUserResponse.fromJSON(m)})
    })
  }
  
}export interface ICampaignWinningDistribution {
  campaignWinningsDistribution: (data: CampaignWinningsDistributionRequest, headers?: object) => Promise<CampaignWinningsDistributionResponse>
}

export class CampaignWinningDistribution implements ICampaignWinningDistribution {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.CampaignWinningDistribution/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public campaignWinningsDistribution(params: CampaignWinningsDistributionRequest, headers: object = {}): Promise<CampaignWinningsDistributionResponse> {
    return this.fetch(
      this.url('CampaignWinningsDistribution'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CampaignWinningsDistributionResponse.fromJSON(m)})
    })
  }
  
}export interface ICatalogService {
  addProduct: (data: AnyProduct, headers?: object) => Promise<AnyProduct>
}

export class CatalogService implements ICatalogService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.CatalogService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public addProduct(params: AnyProduct, headers: object = {}): Promise<AnyProduct> {
    return this.fetch(
      this.url('AddProduct'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return AnyProduct.fromJSON(m)})
    })
  }
  
}export interface ICashFormatTournament {
  purchaseTicket: (data: PurchaseTicketRequest, headers?: object) => Promise<PurchaseTicketResponse>
  leaveTable: (data: LeaveTableRequest, headers?: object) => Promise<LeaveTableResponse>
  topUp: (data: TopUpRequest, headers?: object) => Promise<TopUpResponse>
}

export class CashFormatTournament implements ICashFormatTournament {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.CashFormatTournament/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public purchaseTicket(params: PurchaseTicketRequest, headers: object = {}): Promise<PurchaseTicketResponse> {
    return this.fetch(
      this.url('PurchaseTicket'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return PurchaseTicketResponse.fromJSON(m)})
    })
  }
  
  public leaveTable(params: LeaveTableRequest, headers: object = {}): Promise<LeaveTableResponse> {
    return this.fetch(
      this.url('LeaveTable'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return LeaveTableResponse.fromJSON(m)})
    })
  }
  
  public topUp(params: TopUpRequest, headers: object = {}): Promise<TopUpResponse> {
    return this.fetch(
      this.url('TopUp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TopUpResponse.fromJSON(m)})
    })
  }
  
}export interface IGameService {
  addGame: (data: Game, headers?: object) => Promise<Game>
  updateTriviaInstantBundle: (data: GameBundle, headers?: object) => Promise<GameBundle>
}

export class GameService implements IGameService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.GameService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public addGame(params: Game, headers: object = {}): Promise<Game> {
    return this.fetch(
      this.url('AddGame'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Game.fromJSON(m)})
    })
  }
  
  public updateTriviaInstantBundle(params: GameBundle, headers: object = {}): Promise<GameBundle> {
    return this.fetch(
      this.url('UpdateTriviaInstantBundle'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return GameBundle.fromJSON(m)})
    })
  }
  
}export interface ITournamentGameServer {
  verifyPlayerPoolAndCreateRoom: (data: VerifyPlayerPoolAndCreateRoomRequest, headers?: object) => Promise<VerifyPlayerPoolAndCreateRoomResponse>
  checkRematchEligibility: (data: CheckRematchEligibilityRequest, headers?: object) => Promise<CheckRematchEligibilityResponse>
  verifyPlayerPoolAndCreateRematchRoom: (data: VerifyPlayerPoolAndCreateRematchRoomRequest, headers?: object) => Promise<VerifyPlayerPoolAndCreateRematchRoomResponse>
  submitGameServerMatchScore: (data: SubmitGameServerMatchScoreRequest, headers?: object) => Promise<SubmitGameServerMatchScoreResponse>
  cancelTournamentRoom: (data: CancelTournamentRoomRequest, headers?: object) => Promise<CancelTournamentRoomResponse>
  updateRoomWithMatchId: (data: UpdateRoomWithMatchIdRequest, headers?: object) => Promise<UpdateRoomWithMatchIdResponse>
  updateSessionWithMatchId: (data: UpdateSessionWithMatchIdRequest, headers?: object) => Promise<UpdateSessionWithMatchIdResponse>
  getSessionInfoForRound: (data: GetSessionInfoForRoundRequest, headers?: object) => Promise<GetSessionInfoForRoundResponse>
}

export class TournamentGameServer implements ITournamentGameServer {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.TournamentGameServer/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public verifyPlayerPoolAndCreateRoom(params: VerifyPlayerPoolAndCreateRoomRequest, headers: object = {}): Promise<VerifyPlayerPoolAndCreateRoomResponse> {
    return this.fetch(
      this.url('VerifyPlayerPoolAndCreateRoom'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return VerifyPlayerPoolAndCreateRoomResponse.fromJSON(m)})
    })
  }
  
  public checkRematchEligibility(params: CheckRematchEligibilityRequest, headers: object = {}): Promise<CheckRematchEligibilityResponse> {
    return this.fetch(
      this.url('CheckRematchEligibility'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CheckRematchEligibilityResponse.fromJSON(m)})
    })
  }
  
  public verifyPlayerPoolAndCreateRematchRoom(params: VerifyPlayerPoolAndCreateRematchRoomRequest, headers: object = {}): Promise<VerifyPlayerPoolAndCreateRematchRoomResponse> {
    return this.fetch(
      this.url('VerifyPlayerPoolAndCreateRematchRoom'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return VerifyPlayerPoolAndCreateRematchRoomResponse.fromJSON(m)})
    })
  }

  public submitGameServerMatchScore(params: SubmitGameServerMatchScoreRequest, headers: object = {}): Promise<SubmitGameServerMatchScoreResponse> {
    return this.fetch(
      this.url('SubmitGameServerMatchScore'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return SubmitGameServerMatchScoreResponse.fromJSON(m)})
    })
  }
  
  public cancelTournamentRoom(params: CancelTournamentRoomRequest, headers: object = {}): Promise<CancelTournamentRoomResponse> {
    return this.fetch(
      this.url('CancelTournamentRoom'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CancelTournamentRoomResponse.fromJSON(m)})
    })
  }
  
  public updateRoomWithMatchId(params: UpdateRoomWithMatchIdRequest, headers: object = {}): Promise<UpdateRoomWithMatchIdResponse> {
    return this.fetch(
      this.url('UpdateRoomWithMatchId'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UpdateRoomWithMatchIdResponse.fromJSON(m)})
    })
  }
  
  public updateSessionWithMatchId(params: UpdateSessionWithMatchIdRequest, headers: object = {}): Promise<UpdateSessionWithMatchIdResponse> {
    return this.fetch(
      this.url('UpdateSessionWithMatchId'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UpdateSessionWithMatchIdResponse.fromJSON(m)})
    })
  }
  
  public getSessionInfoForRound(params: GetSessionInfoForRoundRequest, headers: object = {}): Promise<GetSessionInfoForRoundResponse> {
    return this.fetch(
      this.url('GetSessionInfoForRound'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return GetSessionInfoForRoundResponse.fromJSON(m)})
    })
  }
  
}export interface ILeaderBoards {
  createLeaderBoard: (data: CreateLeaderBoardRequest, headers?: object) => Promise<CreateLeaderBoardResponse>
}

export class LeaderBoards implements ILeaderBoards {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.LeaderBoards/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createLeaderBoard(params: CreateLeaderBoardRequest, headers: object = {}): Promise<CreateLeaderBoardResponse> {
    return this.fetch(
      this.url('CreateLeaderBoard'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CreateLeaderBoardResponse.fromJSON(m)})
    })
  }
  
}export interface ILeaderBoardsTicker {
  updateStates: (data: protobuf_Empty, headers?: object) => Promise<protobuf_Empty>
}

export class LeaderBoardsTicker implements ILeaderBoardsTicker {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.LeaderBoardsTicker/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public updateStates(params: protobuf_Empty, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('UpdateStates'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface IOMS_Support {
  fulfillOrder: (data: FulfillOrderRequest, headers?: object) => Promise<protobuf_Empty>
  processGemsOrder: (data: ProcessPaymentRequest, headers?: object) => Promise<ProcessGemsOrderResponse>
  failOrder: (data: FailOrderRequest, headers?: object) => Promise<protobuf_Empty>
}

export class OMS_Support implements IOMS_Support {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.OMS_Support/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public fulfillOrder(params: FulfillOrderRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('FulfillOrder'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
  public processGemsOrder(params: ProcessPaymentRequest, headers: object = {}): Promise<ProcessGemsOrderResponse> {
    return this.fetch(
      this.url('ProcessGemsOrder'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ProcessGemsOrderResponse.fromJSON(m)})
    })
  }
  
  public failOrder(params: FailOrderRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('FailOrder'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface IPubsub {
  gCSNotification: (data: PubsubMessage, headers?: object) => Promise<protobuf_Empty>
}

export class Pubsub implements IPubsub {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.Pubsub/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public gCSNotification(params: PubsubMessage, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('GCSNotification'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface IRefund {
  initiate: (data: RefundInitiateRequest, headers?: object) => Promise<RefundInitiateResponse>
  process: (data: RefundProcessRequest, headers?: object) => Promise<RefundProcessResponse>
}

export class Refund implements IRefund {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.Refund/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public initiate(params: RefundInitiateRequest, headers: object = {}): Promise<RefundInitiateResponse> {
    return this.fetch(
      this.url('Initiate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RefundInitiateResponse.fromJSON(m)})
    })
  }
  
  public process(params: RefundProcessRequest, headers: object = {}): Promise<RefundProcessResponse> {
    return this.fetch(
      this.url('Process'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RefundProcessResponse.fromJSON(m)})
    })
  }
  
}export interface IReward {
  initiate: (data: RewardInitiateRequest, headers?: object) => Promise<RewardInitiateResponse>
  process: (data: RewardProcessRequest, headers?: object) => Promise<RewardProcessResponse>
  giveRewardToPlayer: (data: GiveRewardToPlayerRequest, headers?: object) => Promise<GiveRewardToPlayerResponse>
}

export class Reward implements IReward {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.Reward/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public initiate(params: RewardInitiateRequest, headers: object = {}): Promise<RewardInitiateResponse> {
    return this.fetch(
      this.url('Initiate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RewardInitiateResponse.fromJSON(m)})
    })
  }
  
  public process(params: RewardProcessRequest, headers: object = {}): Promise<RewardProcessResponse> {
    return this.fetch(
      this.url('Process'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RewardProcessResponse.fromJSON(m)})
    })
  }
  
  public giveRewardToPlayer(params: GiveRewardToPlayerRequest, headers: object = {}): Promise<GiveRewardToPlayerResponse> {
    return this.fetch(
      this.url('GiveRewardToPlayer'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return GiveRewardToPlayerResponse.fromJSON(m)})
    })
  }
  
}export interface IScheduleService {
  createSchedule: (data: Schedule, headers?: object) => Promise<Schedule>
  archiveSchedule: (data: Schedule, headers?: object) => Promise<protobuf_Empty>
}

export class ScheduleService implements IScheduleService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.ScheduleService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createSchedule(params: Schedule, headers: object = {}): Promise<Schedule> {
    return this.fetch(
      this.url('CreateSchedule'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Schedule.fromJSON(m)})
    })
  }
  
  public archiveSchedule(params: Schedule, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ArchiveSchedule'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface ITagService {
  createTag: (data: Tag, headers?: object) => Promise<Tag>
}

export class TagService implements ITagService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.TagService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createTag(params: Tag, headers: object = {}): Promise<Tag> {
    return this.fetch(
      this.url('CreateTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Tag.fromJSON(m)})
    })
  }
  
}export interface ITemplateService {
  submitTemplate: (data: Template, headers?: object) => Promise<Template>
  replaceTemplate: (data: ReplaceRequest, headers?: object) => Promise<Template>
  simulateTemplate: (data: Template, headers?: object) => Promise<SimulationResult>
  archiveTemplate: (data: ArchiveTemplateRequest, headers?: object) => Promise<protobuf_Empty>
  pauseTemplate: (data: PauseTemplateRequest, headers?: object) => Promise<protobuf_Empty>
  resumeTemplate: (data: ResumeTemplateRequest, headers?: object) => Promise<protobuf_Empty>
  cancelTemplatePauseInterval: (data: CancelTemplatePauseIntervalRequest, headers?: object) => Promise<protobuf_Empty>
}

export class TemplateService implements ITemplateService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.TemplateService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public submitTemplate(params: Template, headers: object = {}): Promise<Template> {
    return this.fetch(
      this.url('SubmitTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Template.fromJSON(m)})
    })
  }
  
  public replaceTemplate(params: ReplaceRequest, headers: object = {}): Promise<Template> {
    return this.fetch(
      this.url('ReplaceTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Template.fromJSON(m)})
    })
  }
  
  public simulateTemplate(params: Template, headers: object = {}): Promise<SimulationResult> {
    return this.fetch(
      this.url('SimulateTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return SimulationResult.fromJSON(m)})
    })
  }
  
  public archiveTemplate(params: ArchiveTemplateRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ArchiveTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
  public pauseTemplate(params: PauseTemplateRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('PauseTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
  public resumeTemplate(params: ResumeTemplateRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ResumeTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
  public cancelTemplatePauseInterval(params: CancelTemplatePauseIntervalRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('CancelTemplatePauseInterval'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface ITickerService {
  roomStateSwitchService: (data: protobuf_Empty, headers?: object) => Promise<RoomStateSwitchResponse>
  draftRoomCreationService: (data: protobuf_Empty, headers?: object) => Promise<DraftRoomCreationResponse>
  templatePausePlayService: (data: protobuf_Empty, headers?: object) => Promise<TemplatePausePlayResponse>
  templateStateSwitchService: (data: protobuf_Empty, headers?: object) => Promise<TemplateStateSwitchResponse>
  tournamentStateSwitchService: (data: protobuf_Empty, headers?: object) => Promise<TournamentStateSwitchResponse>
  draftTournamentCreationService: (data: protobuf_Empty, headers?: object) => Promise<DraftTournamentCreationResponse>
  scheduleEndAlert: (data: protobuf_Empty, headers?: object) => Promise<protobuf_Empty>
}

export class TickerService implements ITickerService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.TickerService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public roomStateSwitchService(params: protobuf_Empty, headers: object = {}): Promise<RoomStateSwitchResponse> {
    return this.fetch(
      this.url('RoomStateSwitchService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RoomStateSwitchResponse.fromJSON(m)})
    })
  }
  
  public draftRoomCreationService(params: protobuf_Empty, headers: object = {}): Promise<DraftRoomCreationResponse> {
    return this.fetch(
      this.url('DraftRoomCreationService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return DraftRoomCreationResponse.fromJSON(m)})
    })
  }
  
  public templatePausePlayService(params: protobuf_Empty, headers: object = {}): Promise<TemplatePausePlayResponse> {
    return this.fetch(
      this.url('TemplatePausePlayService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TemplatePausePlayResponse.fromJSON(m)})
    })
  }
  
  public templateStateSwitchService(params: protobuf_Empty, headers: object = {}): Promise<TemplateStateSwitchResponse> {
    return this.fetch(
      this.url('TemplateStateSwitchService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TemplateStateSwitchResponse.fromJSON(m)})
    })
  }
  
  public tournamentStateSwitchService(params: protobuf_Empty, headers: object = {}): Promise<TournamentStateSwitchResponse> {
    return this.fetch(
      this.url('TournamentStateSwitchService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TournamentStateSwitchResponse.fromJSON(m)})
    })
  }
  
  public draftTournamentCreationService(params: protobuf_Empty, headers: object = {}): Promise<DraftTournamentCreationResponse> {
    return this.fetch(
      this.url('DraftTournamentCreationService'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return DraftTournamentCreationResponse.fromJSON(m)})
    })
  }
  
  public scheduleEndAlert(params: protobuf_Empty, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ScheduleEndAlert'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface ITournamentTemplateService {
  submitTournamentTemplate: (data: TournamentTemplate, headers?: object) => Promise<TournamentTemplate>
  replaceTournamentTemplate: (data: ReplaceRequest, headers?: object) => Promise<TournamentTemplate>
  simulateTournamentTemplate: (data: TournamentTemplate, headers?: object) => Promise<SimulationResult>
  archiveTournamentTemplate: (data: ArchiveTournamentTemplateRequest, headers?: object) => Promise<protobuf_Empty>
}

export class TournamentTemplateService implements ITournamentTemplateService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.TournamentTemplateService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public submitTournamentTemplate(params: TournamentTemplate, headers: object = {}): Promise<TournamentTemplate> {
    return this.fetch(
      this.url('SubmitTournamentTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TournamentTemplate.fromJSON(m)})
    })
  }
  
  public replaceTournamentTemplate(params: ReplaceRequest, headers: object = {}): Promise<TournamentTemplate> {
    return this.fetch(
      this.url('ReplaceTournamentTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TournamentTemplate.fromJSON(m)})
    })
  }
  
  public simulateTournamentTemplate(params: TournamentTemplate, headers: object = {}): Promise<SimulationResult> {
    return this.fetch(
      this.url('SimulateTournamentTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return SimulationResult.fromJSON(m)})
    })
  }
  
  public archiveTournamentTemplate(params: ArchiveTournamentTemplateRequest, headers: object = {}): Promise<protobuf_Empty> {
    return this.fetch(
      this.url('ArchiveTournamentTemplate'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return protobuf_Empty.fromJSON(m)})
    })
  }
  
}export interface IWatson {
  getPanDetails: (data: KycPanDetailsRequest, headers?: object) => Promise<KycDocumentDetailsResponse>
  getKycDocumentDetails: (data: KycDocumentDetailsRequest, headers?: object) => Promise<KycDocumentDetailsResponse>
  updateDocumentStatus: (data: KycUpdateDocumentStatusRequest, headers?: object) => Promise<KycDocumentDetailsResponse>
  provideWildUploads: (data: KycProvideWildUploadsRequest, headers?: object) => Promise<KycProvideWildUploadsResponse>
}

export class Watson implements IWatson {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.Watson/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getPanDetails(params: KycPanDetailsRequest, headers: object = {}): Promise<KycDocumentDetailsResponse> {
    return this.fetch(
      this.url('GetPanDetails'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return KycDocumentDetailsResponse.fromJSON(m)})
    })
  }
  
  public getKycDocumentDetails(params: KycDocumentDetailsRequest, headers: object = {}): Promise<KycDocumentDetailsResponse> {
    return this.fetch(
      this.url('GetKycDocumentDetails'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return KycDocumentDetailsResponse.fromJSON(m)})
    })
  }
  
  public updateDocumentStatus(params: KycUpdateDocumentStatusRequest, headers: object = {}): Promise<KycDocumentDetailsResponse> {
    return this.fetch(
      this.url('UpdateDocumentStatus'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return KycDocumentDetailsResponse.fromJSON(m)})
    })
  }
  
  public provideWildUploads(params: KycProvideWildUploadsRequest, headers: object = {}): Promise<KycProvideWildUploadsResponse> {
    return this.fetch(
      this.url('ProvideWildUploads'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return KycProvideWildUploadsResponse.fromJSON(m)})
    })
  }
  
}export interface IWinningsDistributionService {
  getWinningsDistribution: (data: WinningsDistributionRequest, headers?: object) => Promise<WinningsDistributionList>
}

export class WinningsDistributionService implements IWinningsDistributionService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/pb.WinningsDistributionService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getWinningsDistribution(params: WinningsDistributionRequest, headers: object = {}): Promise<WinningsDistributionList> {
    return this.fetch(
      this.url('GetWinningsDistribution'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return WinningsDistributionList.fromJSON(m)})
    })
  }
  
}